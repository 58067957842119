export const signedValue = (value: string | null, sign: string) => {
  if (sign === '$') {
    return {
      value: (value && sign) ? sign + value : ''
    };
  }
  return {
    value: (value && sign) ? value + sign : ''
  }
};

export const numberFormatter = (value: number, accuracy: number = 2): string | null => {
  if (value || value === 0) {
    return Number.isInteger(value) ? value.toString() : Number(value).toFixed(accuracy);
  }
  return null;
}
export const formatNumberToIncludeCommas = (value: number | undefined) => {
  return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
}

export const getDateFromString = (dateString: string) => {
  const dateParts = dateString.split('/');
  return new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
};

export const dateCellComparator = (dateFromFilter: Date, cellValue: string) => {
  const cellDate = getDateFromString(cellValue);
  if (cellDate.getTime() === dateFromFilter.getTime()) {
    return 0;
  }
  return (cellDate <= dateFromFilter) ? -1 : 1;
};

export const dateCellSorter = (a: string, b: string) => {
  if (!a && !b) {
    return 0;
  }
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }
  const aDate = getDateFromString(a);
  const bDate = getDateFromString(b);
  return (aDate < bDate) ? -1 : 1;
};

export const sortByNestedKey = (array: any, key1: string | number, key2: string | number) => {
  return array.sort((a: any, b: any) => {
    if (a[key1][key2] > b[key1][key2]) {
      return -1;
    }
    if (a[key1][key2] < b[key1][key2]) {
      return 1;
    }
    return 0;
  });

};

export const capitalizeFirstLetterOfSentences = (text: string) => {
  return text.replace(/(^\s*\w|[.!?]\s*\w)/g, (char: string) => {
    return char.toUpperCase();
  });
};

export const debounce = (func: Function, delay: number) => {
  let timer: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
}

const deepSortedStringify = (obj: any, excludeFields: string[] = []): string => {
  if (obj === null || typeof obj !== 'object') return JSON.stringify(obj);

  const sortedObj = Array.isArray(obj)
    ? obj.map((item) => deepSortedStringify(item, excludeFields))
    : Object.keys(obj)
            .sort()
            .reduce((result, key) => {
              if (!excludeFields.includes(key)) {
                result[key] = deepSortedStringify(obj[key], excludeFields);
              }
              return result;
            }, {} as Record<string, any>);
  return JSON.stringify(sortedObj);
};

export const generateChecksum = (item: any) => {
  const str = deepSortedStringify(item, ['id', 'checksum']);
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }
  return hash;
};

