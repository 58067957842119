import { selector } from "recoil";
import { CampaignsServiceProvider } from "../../../../../../../../../../services/campaigns/CampaignsServiceProvider";


export const campaignData = selector({
  key: 'campaignDataSelector',
  get: async () => {
    try {
      const response = await CampaignsServiceProvider.getCampaignData();
      return response;
    } catch (error) {
      const errorMessage = 'Error fetching campaign data:';
      throw new Error(errorMessage);
    }
  },
});
