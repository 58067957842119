/** @jsxImportSource @emotion/react */
import { Configuration } from '../../../configurations.types';
import React, { useState } from 'react';
import { useNavigateToPage } from '../../../../../hooks/useNavigate';
import { getColumnsDefinition } from '../../configurationsTablePage.utils';
import { ConfigurationsServiceConfigsProvider } from '../../../../../services/configurations/ConfigurationsServiceConfigsProvider';
import { Button, ButtonType, Modal, NoData, RowData, TableNew, Text } from '@ftbpro/mm-admin-ui-components';
import { DeleteIcon, EditIcon } from '@ftbpro/mm-admin-assets';
import { isEmpty } from '@ftbpro/mm-admin-core-utils';
import { css } from '@emotion/react';

const styles = {
  noDataWrapper: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    backgroundColor: '#FFF',
  }),
  tableWrapper: css({
    overflowY: 'auto',
    height: '100vh',
  }),
  modalButtons: css({
    display: 'flex',
    justifyContent: 'end',
    marginTop: '32px',
  }),
  cancelButton: css({
    marginRight: '8px',
  }),
};

interface ConfigurationsContentProps {
  configurationsTableData: Configuration[];
  getConfigurationsData: () => Promise<void>;
  setIsLoading: (val: boolean) => void;
}

const ConfigurationsTableContentComponent = ({ configurationsTableData, getConfigurationsData, setIsLoading }: ConfigurationsContentProps) => {
  const [isModal, setIsModal] = useState(false);
  const [selectedConfiguration, setSelectedConfiguration] = useState<{ id: string, name: string }>({ id: '', name: '' });
  const navigate = useNavigateToPage();
  const columnsDefinition = getColumnsDefinition();

  const deleteConfiguration = async () => {
    setIsLoading(true);
    await ConfigurationsServiceConfigsProvider.deleteConfiguration(selectedConfiguration.id);
    await getConfigurationsData();
    setIsModal(false);
  };

  const generateActionElementsFn = (data: RowData) => {
    return [{
      actionName: 'Edit',
      onClick: () => {
        navigate(`campaign-settings/${data.id}`);
      },
      icon: EditIcon,
    },
      {
        actionName: 'Delete',
        onClick: () => {
          setSelectedConfiguration({ id: data.id, name: data.name });
          setIsModal(true);
        },
        icon: DeleteIcon,
      },];
  };

  return (
    <div>
      {<Modal
        closeable
        onClose={() => setIsModal(false)}
        title="Delete Campaign Settings"
        open={isModal}
      >
        <div>
          <Text
            type={Text.TEXT_TYPES.PARAGRAPH_L}>
            {`Are you sure you want to permanently delete "${selectedConfiguration.name}" campaign settings?`}
          </Text>
          <div css={styles.modalButtons}>
            <Button
              content="Cancel"
              onClick={() => setIsModal(false)}
              type={ButtonType.Secondary}
              style={styles.cancelButton}
            />
            <Button
              content="Delete"
              onClick={deleteConfiguration}
            />
          </div>
        </div>
      </Modal>}
      {isEmpty(configurationsTableData)
        ? <div css={styles.noDataWrapper}><NoData/></div>
        : <div css={styles.tableWrapper}>
          <TableNew
            data={configurationsTableData}
            columnsDefinition={columnsDefinition}
            defaultColDef={{ minWidth: 130 }}
            pagination
            generateActionElementsFn={generateActionElementsFn}
          />
        </div>
      }
    </div>
  );
};

export const ConfigurationsTableContent = React.memo(ConfigurationsTableContentComponent);
