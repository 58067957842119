import { FontWeight, RadioGroup, Text } from '@ftbpro/mm-admin-ui-components';
import { Card } from '../../../Card/Card';
import { SearchCampaign, SearchProvider } from '../../../../../../createCampign.typs';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { targetState } from '../../targetState/target.atom';
import { useLocation } from 'react-router-dom';
import { TonicCampaign } from './components/TonicCampaign/TonicCampaign';
import { ExploreAdsCampaign } from './components/ExploreAdsCampaign/ExploreAdsCampaign';
import { AFSCampaign } from './components/AFSCampaign/AFSCampaign';
import { useEffect } from 'react';
import { settingsState } from '../../../Settings/settingsState/settings.atom';
import { campaignsDraftTriggeredState } from '../../../../../../createCampaignState/createCampaign.selector';
import { searchState } from './searchCampaign.atom';
import { duplicateData, isDuplicateState } from '../../../Settings/components/DuplicateCampaigns/duplicate.state';

const SEARCH_PROVIDER = [
  {
    label: 'Tonic',
    value: SearchProvider.Tonic,
  },
  {
    label: 'ExploreAds',
    value: SearchProvider.ExploreAds,
  },
  {
    label: 'AFS',
    value: SearchProvider.AFS,
  }
]

export const SearchCampaignContainer = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const { triggered: applyDraftTriggered } = useRecoilValue(campaignsDraftTriggeredState);
  const setSettingsState = useSetRecoilState(settingsState);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isDuplicate = useRecoilValue(isDuplicateState);
  const { isSearch: duplicateIsSearch, searchProvider: duplicateSearchProvider } = useRecoilValue(duplicateData) || {};
  const isSearchCampaign = isDuplicate ? duplicateIsSearch as boolean : queryParams.get('isSearch') === 'True';
  const { searchCampaign } = target;
  const searchProvider = isDuplicate ? duplicateSearchProvider  : searchCampaign?.searchProvider;
  const setIsSearchCampaign = useSetRecoilState(searchState)

  useEffect(() => {
    setIsSearchCampaign({isSearchCampaign})
    setTarget((prevTarget) => ({
      ...prevTarget,
      searchCampaign: {
        searchProvider
      } as SearchCampaign
    }));
  }, [isSearchCampaign, searchProvider]);

  const onSearchProviderChange = (value: string) => {
    setTarget((prevTarget) => ({
      ...prevTarget,
      searchCampaign: {
        searchProvider: value,
      } as SearchCampaign
    }));

    setSettingsState((prevSettings) => ({
      ...prevSettings,
      accountsDomains: [{domain: '', accounts: []}]
    }));
  }

  return (
    isSearchCampaign ?
      <Card>
        <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium} >Search Campaign</Text>
        <RadioGroup
          items={SEARCH_PROVIDER}
          orientation={RadioGroup.ORIENTATION.HORIZONTAL}
          onCheckChanged={onSearchProviderChange}
          checkedValue={searchProvider}
          disabled={applyDraftTriggered || isDuplicate}
        />
        {
          searchProvider === SearchProvider.Tonic &&
          <TonicCampaign />
        }

        {
          searchProvider === SearchProvider.ExploreAds &&
          <ExploreAdsCampaign />
        }

        {
          searchProvider === SearchProvider.AFS &&
          <AFSCampaign />
        }
      </Card>
      : null
  );
}
