/** @jsxImportSource @emotion/react */
import { DateInput } from '@ftbpro/mm-admin-ui-components';
import { TimeInput } from "../TimeInput/TimeInput";
import { css } from "@emotion/react";
import { useRecoilState } from "recoil";
import { settingsState } from '../../../settingsState/settings.atom';


const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  }),
}



export const CustomDate = () => {
  const [settings, setSettings] = useRecoilState(settingsState);
  const now = new Date();

  const onDateChange = (date: Date | null) => {
    if (date) {
      setSettings((prevSettings) => {
        const copyCustomDate = prevSettings.customDate ? { ...prevSettings.customDate } : { hour: '00', minutes: '00' };
        return {
          ...prevSettings,
          customDate: {
            ...copyCustomDate,
            date: date,
          }
        }
      });
    }
  }

  return (
    <div css={styles.container}>
      <DateInput
        selectedDate={settings.customDate?.date ? settings.customDate?.date : undefined}
        variables={{
          dropdownWidth: '169px',
        }}
        onDateSelect={onDateChange}
        minDate={now}
      />
      <TimeInput
        standartTime="Est"
      />
    </div>
  );
}
