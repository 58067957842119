import { PlatformsType } from '../../pages/groups/groups.enums';
import { DATE_FORMATS, formatDate } from '@ftbpro/mm-admin-core-utils';
import { Configuration } from '../../pages/configurations/configurations.types';

export const formatPlatformType = (type: string): (PlatformsType | string) => {
  switch (type) {
    case 'google':
      return PlatformsType.Google;
    case 'facebook':
      return PlatformsType.Facebook;
    case 'outbrain':
      return PlatformsType.Outbrain;
    case 'taboola':
      return PlatformsType.Taboola;
    default:
      return '';
  }
};

export const formatConfigurations = (configurationsList: Configuration[]) => {
  return configurationsList.map(group => {
    const { platform, createdAt, ...rest } = group;
    return {
      ...rest,
      platform: formatPlatformType(platform),
      createdAt: createdAt ? formatDate(createdAt, DATE_FORMATS.DATE) : '',
    };
  });
};

