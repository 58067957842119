import { HTTP_METHODS, mmNetworkService } from '../../core/network/mmServicesApiProvider';
import { Configuration } from '../../pages/configurations/configurations.types';

const PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL = process.env.REACT_APP_PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL;

const CONFIGURATIONS = 'preferences';

export type ConfigurationsResponse = {
  configs: Configuration[];
}


export class ConfigurationsServiceConfigsProvider {

  static configurationsEndpoint() {
    return `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/${CONFIGURATIONS}`;
  }

  static configurationEndpoint(configurationId: string) {
    return `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/${CONFIGURATIONS}/${configurationId}`;
  }

  static getConfigurations(currentUser?: boolean): Promise<ConfigurationsResponse> {
    const queryParams = new URLSearchParams('');
    if (currentUser) {
      queryParams.set('currentUser', currentUser.toString());
    }
    const url = `${ConfigurationsServiceConfigsProvider.configurationsEndpoint()}?${queryParams.toString()}`;
    const configs = {
      method: HTTP_METHODS.GET,
    };

    return mmNetworkService.fetch(url, configs);
  }

  static getConfiguration(configurationId: string): Promise<Configuration> {
    const url = ConfigurationsServiceConfigsProvider.configurationEndpoint(configurationId);
    const configs = {
      method: HTTP_METHODS.GET,
    };
    return mmNetworkService.fetch(url, configs);
  }

  static createConfiguration(configuration: Configuration): Promise<any> {
    const url = ConfigurationsServiceConfigsProvider.configurationsEndpoint();

    const configs = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(configuration),
    };
    return mmNetworkService.fetch(url, configs);
  }

  static updateConfiguration(configurationId: string, configuration: Configuration): Promise<any> {
    const url = ConfigurationsServiceConfigsProvider.configurationEndpoint(configurationId);

    const configs = {
      method: HTTP_METHODS.PUT,
      body: JSON.stringify(configuration),
    };
    return mmNetworkService.fetch(url, configs);
  }

  static deleteConfiguration(id: string) {
    const url = ConfigurationsServiceConfigsProvider.configurationEndpoint(id);
    const configs = {
      method: HTTP_METHODS.DELETE,
    };

    return mmNetworkService.fetch(url, configs);
  }
}
