import { Dropdown, DropdownListItemProps } from "@ftbpro/mm-admin-ui-components"
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { targetState } from "../../../../../../targetState/target.atom";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { campaignData } from "../../../../../../targetState/target.selector";

export const AudiencesLookALike = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const data = useRecoilValueLoadable(campaignData);
  const selectedAudiencesLookALike = target.customAudiences?.lookalike?.map((audiencesLookALike) => (audiencesLookALike.id));

  const getAudiencesLookALike = () => {
    if (data.state === 'hasValue') {
      const audiencesLookALike = data.contents.audience.facebook.customAudiences.lookalike;
      return audiencesLookALike.map((audiencesLookALike: { name: string, id: string }) => ({ content: audiencesLookALike.name, value: audiencesLookALike.id }));
    }
    return [];
  }

  const onSelectedChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setTarget((prevTarget) => {
      const copyAudiencesLookALike = prevTarget.customAudiences?.lookalike ? [...prevTarget.customAudiences?.lookalike] : [];
      return {
        ...prevTarget,
        customAudiences: {
          ...prevTarget.customAudiences,
          lookalike: [...copyAudiencesLookALike, { id: selectedItem.value as string, name: selectedItem.content as string }]
        }
      }
    });
  }

  const onAudiencesLookALikeDelete = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        customAudiences: {
          ...prevTarget.customAudiences,
          lookalike: prevTarget.customAudiences?.lookalike?.filter((audiencesLookALike) => audiencesLookALike.id !== selectedItem.value)
        }
      }
    });
  }

  const onAudiencesLookALikeSelectAll = (e: ClickEventType<HTMLElement>, selectedItems: DropdownListItemProps[]) => {
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        customAudiences: {
          ...prevTarget.customAudiences,
          lookalike: selectedItems.map((item) => ({ id: item.value as string, name: item.content as string }))
        }
      }
    });
  }

  const searchPredicate = (query: string | number, item: DropdownListItemProps) => {
    const stringQuery = `${query}`;
    const value = item.content as string;
    return value?.toLocaleLowerCase().includes(stringQuery.toLocaleLowerCase());
  };


  return (
    <Dropdown
      items={getAudiencesLookALike()}
      loading={data.state === 'loading'}
      placeholder={"Custom Audiences LookALike"}
      selectAllLabel={'Select All'}
      multiSelection
      showCheckboxes
      expandableChips
      search
      onSelectedChange={onSelectedChange}
      onValueRemove={onAudiencesLookALikeDelete}
      onSelectAllChange={onAudiencesLookALikeSelectAll}
      searchPredicate={searchPredicate}
      selectedValues={selectedAudiencesLookALike}
    />
  )
}
