import React from 'react';
import { Text } from '@ftbpro/mm-admin-ui-components';

export const TooltipContent = ({ messages }: { messages: string[] }) => {
  return (
    <>
      {messages.map((message, index) => (
        <Text key={index} type={Text.TEXT_TYPES.PARAGRAPH_M}>{`- ${message}`}</Text>
      ))}
    </>
  );
};
