import { Dropdown, DropdownListItemProps } from "@ftbpro/mm-admin-ui-components"
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { targetState } from "../../../../../../targetState/target.atom";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { campaignData } from "../../../../../../targetState/target.selector";


export const CustomPixels = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const data = useRecoilValueLoadable(campaignData);

  const getCustomPixels = () => {
    if (data.state === 'hasValue') {
      return data.contents.audience.facebook.customPixels;
    }
    return [];
  }

  const onSelectedChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        customPixel:  selectedItem.value as string
      }
    });
  }

  return (
    <Dropdown
      items={getCustomPixels()}
      placeholder={"Custom Pixels"}
      search
      onSelectedChange={onSelectedChange}
      loading={data.state === 'loading'}
      selectedValue={target.customPixel}
    />
  )
}
