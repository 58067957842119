/** @jsxImportSource @emotion/react */
import React, { Dispatch, SetStateAction } from 'react';
import { Card, Dropdown, Input, RequiredIndicatorType, Text, Types } from '@ftbpro/mm-admin-ui-components';
import { PlatformsType } from '../../../../../core/enums/enums';
import { css } from '@emotion/react';

const CARD_TITLE = 'Name & Platform';
const NAME_LABEL = 'Name';
const NAME_PLACEHOLDER = 'My new settings';
const PLATFORM_LABEL = 'Platform';
const PLATFORM_PLACEHOLDER = 'Choose Platform';

const styles = {
  nameAndPlatformColumns: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '24px',
  }),
  card: {
    boxShadow: 'none',
  },
};

const variables = {
  card: {
    cardWidth: '100%',
    cardContentOverflow: 'visible',
    cardDividerColor: 'none',
    cardHeaderPadding: '8px 0px 0px 16px',
  },
  dropdown: {
    dropdownWidth: '100%',
  },
};

interface GeneralDataProps {
  configurationName: string;
  setConfigurationName: Dispatch<SetStateAction<string>>;
  selectedPlatform: string;
  setSelectedPlatform: Dispatch<SetStateAction<string>>;
  isEditing: boolean;
}

export const GeneralData = (props: GeneralDataProps) => {
  const {
    configurationName,
    setConfigurationName,
    selectedPlatform,
    setSelectedPlatform,
    isEditing,
  } = props;

  return (
    <Card
      title={CARD_TITLE}
      textType={Text.TEXT_TYPES.TITLE_S}
      variables={variables.card}
      style={styles.card}
    >
      <div css={styles.nameAndPlatformColumns}>
        <Input
          clearable
          onClear={() => {
            setConfigurationName('');
          }}
          labelProps={{
            text: NAME_LABEL,
            requiredIndicator: RequiredIndicatorType.Required,
          }}
          placeholder={NAME_PLACEHOLDER}
          onChange={(e: React.SyntheticEvent<HTMLElement, Event>) => {
            const inputElement = e.target as HTMLInputElement;
            setConfigurationName(inputElement.value);
          }}
          value={configurationName}
          autoHeight
        />
        <Dropdown
          placeholder={PLATFORM_PLACEHOLDER}
          readOnly={isEditing}
          items={[PlatformsType.Facebook]}
          selectedValue={selectedPlatform}
          labelProps={{
            requiredIndicator: isEditing ? RequiredIndicatorType.None : RequiredIndicatorType.Required,
            text: PLATFORM_LABEL,
          }}
          search
          variables={variables.dropdown}
          onSelectedChange={(e: Types.ClickEventType<HTMLElement>, platform: any) => {
            setSelectedPlatform(platform.value.toLowerCase());
          }}
        />
      </div>
    </Card>
  );
};
