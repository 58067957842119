import { Dropdown, DropdownListItemProps } from "@ftbpro/mm-admin-ui-components"
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { targetState } from "../../../../../../targetState/target.atom";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { campaignData } from "../../../../../../targetState/target.selector";

export const AudiencesWebsite = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const data = useRecoilValueLoadable(campaignData);
  const selectedAudiencesWebsite = target.customAudiences?.website?.map(website => website.id) || [];

  const getAudiencesWebsite = () => {
    if (data.state === 'hasValue') {
      const audiencesWebsite = data.contents.audience.facebook.customAudiences.website;
      return audiencesWebsite.map((audiencesWebsite: { name: string, id: string }) => ({ content: audiencesWebsite.name, value: audiencesWebsite.id }));
    }
    return [];
  }

  const onSelectedChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setTarget((prevTarget) => {
      const copyAudiencesWebsite = prevTarget.customAudiences?.website ? [...prevTarget.customAudiences?.website] : [];
      return {
        ...prevTarget,
        customAudiences: {
          ...prevTarget.customAudiences,
          website: [...copyAudiencesWebsite, { id: selectedItem.value as string, name: selectedItem.content as string }]
        }
      }
    });
  }

  const onAudiencesWebsiteDelete = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        customAudiences: {
          ...prevTarget.customAudiences,
          website: prevTarget.customAudiences?.website?.filter((audiencesWebsite) => audiencesWebsite.id !== selectedItem.value)
        }
      }
    });
  }

  const onAudiencesWebsiteSelectAll = (e: ClickEventType<HTMLElement>, selectedItems: DropdownListItemProps[]) => {
    console.log('selectedItems', selectedItems);
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        customAudiences: {
          ...prevTarget.customAudiences,
          website: selectedItems?.map((item) => ({ id: item.value as string, name: item.content as string }))
        }
      }
    });
  }

  const searchPredicate = (query: string | number, item: DropdownListItemProps) => {
    const stringQuery = `${query}`;
    const value = item.content as string;
    return value?.toLocaleLowerCase().includes(stringQuery.toLocaleLowerCase());
  };

  return (
    <Dropdown
      items={getAudiencesWebsite()}
      placeholder={"Custom Audiences Website"}
      selectAllLabel={'Select All'}
      multiSelection
      showCheckboxes
      expandableChips
      search
      onSelectedChange={onSelectedChange}
      loading={data.state === 'loading'}
      onValueRemove={onAudiencesWebsiteDelete}
      onSelectAllChange={onAudiencesWebsiteSelectAll}
      searchPredicate={searchPredicate}
      selectedValues={ selectedAudiencesWebsite }
    />
  )
}
