import { selector } from "recoil";
import { CampaignsServiceProvider } from "../../../../../../../../../../services/campaigns/CampaignsServiceProvider";

export const getDomainAccounts = selector({
  key: 'getDomainAccountsSelector',
  get: async () => {
    try {
      const response = await CampaignsServiceProvider.getDomainAccounts();
      return response;
    } catch (error) {
      const errorMessage = 'Error fetching domains and account';
      throw new Error(errorMessage);
    }
  },
});

