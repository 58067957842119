/** @jsxImportSource @emotion/react */
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, DropdownListItemProps, Filter, Loader, SearchInput, Text } from '@ftbpro/mm-admin-ui-components';
import { capitalizeFirstLetter, isEmpty } from '@ftbpro/mm-admin-core-utils';
import { Group } from '../groups.types';
import { User } from '../../../core/types/users.types';
import { UsersServiceConfigsProvider } from '../../../services/users/UsersServiceConfigsProvider';
import { formatGroups } from '../../../services/users/UsersServiceFormatter';
import { ClickEventType } from '@ftbpro/mm-admin-ui-components/src/types';
import { useNavigateToPage, useOnArrowBackClick } from '../../../hooks/useNavigate';
import { useGroupsData } from '../groupsContext';
import { getUserDetails } from '../../../core/utils/user.utils';
import { GroupsTableContent } from './components/groupsTableContent/GroupsTableContent';
import { css } from '@emotion/react';


const styles = {
  container: css({
    height: '97vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px',
    background: '#F8F7FA',
    minHeight: 0,
  }),
  flexSpacesBetweenRow: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  }),
  titleAndFilterContainer: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '40px',
    alignItems: 'center',
  }),
  filter: css({
    backgroundColor: '#E8E7F0',
    borderRadius: '4px',
  })
};

const variables = {
  filter: {
    buttonTextColor: '#1F0F66',
    toggleIndicatorColor: '#BCB7D2',
    textPadding: '6px 8px 6px 16px',
  }
};

export const GroupsTablePage = () => {
  const [isLoadingGroups, setIsLoadingGroups] = useState(true);
  const [allGroups, setAllGroups] = useState<Group[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { groupData, setGroupData } = useGroupsData();
  const { users } = groupData;
  const userNames = useMemo(() => [{ value: 'All Users', id: 'all' }, ...users.map(user => ({ value: `${capitalizeFirstLetter(user.name)} (${user.email})`, id: user.id }))], [users]);

  const navigate = useNavigateToPage();

  useOnArrowBackClick('groups');

  useEffect(() => {
      getGroupsData()
      .catch(() => {
        setIsLoadingGroups(false);
      });
  }, []);

  useEffect(() => {
    if(!users.length ){
      getUsersList();
    }
  }, []);

  const getAllGroups =  useCallback(async (shouldFilter?: boolean) => {
    const groupsResponse = await UsersServiceConfigsProvider.getGroups();
    setIsLoadingGroups(false);
    const updatedGroups = formatGroups(groupsResponse.groups);
    setAllGroups(updatedGroups);
    if (shouldFilter && (selectedUser || !isEmpty(searchValue))) {
      const filteredGroups = filterGroupsByUserAndSearch(selectedUser, updatedGroups);
      setGroups(filteredGroups);
    } else {
      setGroups(updatedGroups);
    }
  }, [searchValue, selectedUser]);

  const getUserGroup = async () => {
    const userDetails = await UsersServiceConfigsProvider.getUserDetails();
        setIsLoadingGroups(false);
    setAllGroups(userDetails.groups);
    setGroups(formatGroups(userDetails.groups));
  };

  const getGroupsData =  useCallback(async (shouldFilter?: boolean) =>   {
    setIsLoadingGroups(true);
    const userDetails = getUserDetails();
    if (userDetails.permissions.includes('group-management')) {
      await getAllGroups(shouldFilter);
    } else if (userDetails.permissions.includes('groups_owner')) {
      await getUserGroup();
    }
  }, [getAllGroups]);

  const getUsersList = useCallback(async () => {
    setIsLoadingUsers(true);
    const usersResponse = await UsersServiceConfigsProvider.getUsersList();
    setIsLoadingUsers(false);
    setGroupData({ ...groupData, users: usersResponse.users });
  }, [])

  const isMatchingSearchValue = (group: Group, currentSearchValue?: string) => {
    const value = currentSearchValue || searchValue;
    if (isEmpty(value)) return true;
    return group.items?.includes(value) || group.name.toLowerCase().trim().includes(value.toLowerCase().trim());
  }

  const filterGroupsByUser = (selectedUser: User | null) => {
    if (!selectedUser) {
      return allGroups;
    }
    return allGroups.filter(group => selectedUser?.groups.some(userGroup => userGroup.id === group.id));
  };

  const filterGroupsByUserAndSearch = (selectedUser: User | null, groups?: Group[], currentSearchValue?: string) => {
    const groupsToFilter = groups || allGroups;
    if (!selectedUser) {
      return groupsToFilter.filter(group => isMatchingSearchValue(group, currentSearchValue));
    }
     return groupsToFilter.filter(group => selectedUser.groups.some(userGroup => userGroup.id === group.id && isMatchingSearchValue(group, currentSearchValue)));

  };


  const onSelectedUserChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    const { id } = selectedItem as { id: string, value: string };
    if (id === 'all') {
      setGroups(allGroups);
      setSelectedUser(null);
    } else {
      const selectUser = users.find(user => user.id === id) as User;
      const filteredGroups = filterGroupsByUserAndSearch(selectUser);
      setGroups(filteredGroups);
      setSelectedUser(selectUser);
    }
  };

  const onCreateClick = () => {
    navigate('groups/new');
  };


  const  onSearchChange = (e: SyntheticEvent<HTMLElement, Event>, value = '') => {
    if (isEmpty(value)) {
      const filteredGroups = filterGroupsByUser(selectedUser);
      setGroups(filteredGroups);
      setSearchValue('');
    } else {
      setSearchValue(value);
      const filteredGroups = filterGroupsByUserAndSearch(selectedUser, undefined, value);
      setGroups(filteredGroups);
    }
  };

  return (
    <div css={styles.container}>
      <div css={styles.flexSpacesBetweenRow}>
        <div css={styles.titleAndFilterContainer}>
          <Text type={Text.TEXT_TYPES.PARAGRAPH_XXXL}>Group Management</Text>
          <Filter
            loadingItems={isLoadingUsers}
            items={userNames}
            selectedValue={'All Users'}
            onSelectedChange={onSelectedUserChange}
            style={styles.filter}
            variables={variables.filter}
          />
          <SearchInput
          placeholder="Search Groups by Values"
          onChange={onSearchChange}
          onClear={onSearchChange}
          />
        </div>
        <Button content={"Create"}
          onClick={() => {
            onCreateClick()
          }}
        />
      </div>
      {isLoadingGroups
        ? <Loader size={Loader.LOADER_SIZES.FULL_SCREEN} />
        : <GroupsTableContent groupsTableData={groups} setIsLoading={setIsLoadingGroups} getGroupsData={getGroupsData} getUsersList={getUsersList}/>}
    </div>
  );
};
