/** @jsxImportSource @emotion/react */
import { dateCellComparator, dateCellSorter } from '../../../core/utils/utils';
import { SortDirection } from '../../../core/enums/enums';


export const getColumnsDefinition = () => [
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'platform',
    headerName: 'Platform',
  },
  {
    field: 'owner',
    headerName: 'Owner',
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateCellComparator,
    },
    comparator: dateCellSorter,
    sort: SortDirection.Desc,
  },
];
