/** @jsxImportSource @emotion/react */
import { Card, Text } from '@ftbpro/mm-admin-ui-components';
import { GroupType } from '../../../groups.enums';
import React, { Dispatch, SetStateAction } from 'react';
import { groupTypedItem } from '../../../groups.types';
import { getTypeName } from '../../../groups.utils';
import { css } from '@emotion/react';


export const getStylesObject = (isEditing: boolean, isSelected: boolean) => ({
    horizontalRadioButton: css({
        display: 'flex',
        justifyContent: 'flex-start',
    }),
    flexCenter: css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '10px',
        cursor: isEditing ? 'default' : 'pointer'
    }),
    radioButtonInput: css({
        cursor: isEditing ? 'default' : 'pointer',
        accentColor: isEditing ? 'grey' : 'none',
    }),
    radioButtonLabel: css({
        marginLeft: '3px',
        cursor: isEditing ? 'default' : 'pointer',
        textWrap: 'nowrap',
        fontSize: '14px',
        color: (!isSelected && isEditing) ? '#a79e9ec9' : 'black'
    }),
    card: {
        marginBottom: '24px',
        boxShadow: 'none',
        paddingRight: '0px',
    }
});

const variables = {
    card: {
        cardWidth: '100%',
        cardContentOverflow: 'visible',
        cardDividerColor: 'none',
        cardHeaderPadding: '8px 0px 0px 16px',
    }
};

interface SelectGroupTypeProps {
    selectedGroupType: GroupType,
    setSelectedGroupTypedItemList: Dispatch<SetStateAction<groupTypedItem[]>>,
    setSelectedGroupType: Dispatch<SetStateAction<GroupType>>,
    isEditing: boolean,
}


export const SelectGroupType = (props: SelectGroupTypeProps) => {
    const {
        selectedGroupType,
        setSelectedGroupTypedItemList,
        setSelectedGroupType,
        isEditing
    } = props

    const styles = getStylesObject(isEditing, false);
    return (
        <div>
            <Card
                title={(isEditing ? "" : "Select ") + "Group Type"}
                textType={Text.TEXT_TYPES.TITLE_S}
                style={styles.card}
                variables={variables.card}
            >
                <div css={styles.horizontalRadioButton}>
                    {Object.values(GroupType).map((groupType, idx) => (
                        <GroupTypeRadioButton key={idx}
                            groupType={groupType}
                            selectedGroupType={selectedGroupType}
                            setSelectedGroupType={setSelectedGroupType}
                            setSelectedGroupTypedItemList={setSelectedGroupTypedItemList}
                            isEditing={isEditing}/>
                    ))}
                </div>
            </Card>
        </div>
    )
}

interface GroupTypeRadioButtonProps {
    isEditing: boolean,
    selectedGroupType: GroupType,
    groupType: GroupType,
    setSelectedGroupType: Dispatch<SetStateAction<GroupType>>,
    setSelectedGroupTypedItemList: Dispatch<SetStateAction<groupTypedItem[]>>,
}

export const GroupTypeRadioButton = (props: GroupTypeRadioButtonProps) => {
    const {
        isEditing,
        selectedGroupType,
        groupType,
        setSelectedGroupType,
        setSelectedGroupTypedItemList,
    } = props
    const isSelected = selectedGroupType === groupType;
    const styles = getStylesObject(isEditing, isSelected);
    return (
        <span css={styles.flexCenter}>
            <input type="radio" id="`{groupType}-radio-button`"
                checked={isSelected}
                onClick={() => {
                    if (!isEditing) {
                        setSelectedGroupTypedItemList([]);
                        setSelectedGroupType(groupType);
                    }
                }}
                css={styles.radioButtonInput}
                readOnly={true}
                disabled={!isSelected && isEditing}/>
      <label htmlFor={`${groupType}-radio-button`} css={styles.radioButtonLabel}>
        {getTypeName(groupType)}
      </label>
    </span>
    )
}
