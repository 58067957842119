import { DropdownListItemProps } from "@ftbpro/mm-admin-ui-components"
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types"
import { Device } from "../../../../../../createCampign.typs"
import { SelectDevice } from "./components/SelectDevice/SelectDevice"
import { useRecoilState } from "recoil"
import { targetState } from "../../targetState/target.atom"


export const TargetDevices = () => {

  const [target, setTarget] = useRecoilState(targetState);
  const { devices } = target;

  const onSelectedDeviceChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps, index: number) => {
    setTarget((prevState) => {
      return {
        ...prevState,
        devices: prevState.devices.map((device, i) => {
          if (i === index) {
            return {
              ...device,
              device: selectedItem.value as Device
            }
          }
          return device;
        })
      }
    })
  }


  const onBrowsersAndOSChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps, index: number) => {
    setTarget((prevState) => {
      return {
        ...prevState,
        devices: prevState.devices.map((device, i) => {
          if (i === index) {
            return {
              ...device,
              browsersAndOS: selectedItem.value as string
            }
          }
          return device;
        })
      }
    })
  };

  const onDeleteDevice = (index: number) => {
    setTarget((prevState) => {
      return {
        ...prevState,
        devices: prevState.devices.filter((_, i) => i !== index)
      }
    })
  }

  const onAddDevice = () => {
    setTarget((prevState) => {
      return {
        ...prevState,
        devices: [...prevState.devices, { device: '' as Device, browsersAndOS: 'all' }]
      }
    })
  }

  const isValidDevice = (device: Device | '', deviceIndex: number) => {
    if (device === '') {
      return true;
    }
    return !devices.slice(0, deviceIndex).some((d) => d.device === device);
  }

  return (
    <>
      {
        devices.map((device, index) => {
          return <SelectDevice
            selectedDevice={device.device}
            onDeviceChange={onSelectedDeviceChange}
            selectedBrowsersAndOS={device.browsersAndOS}
            onBrowsersAndOSChange={onBrowsersAndOSChange}
            onDeleteDevice={onDeleteDevice}
            onAddDevice={onAddDevice}
            key={index}
            deviceIndex={index}
            showDeleteDeviceIcon={devices.length > 1}
            showAddDeviceIcon={index === devices.length - 1}
            error={isValidDevice(device.device, index)? '' : 'Device already Exists'}
          />
        })
      }
    </>
  )
}
