/** @jsxImportSource @emotion/react */
import { Loader, NoData, RowData, TableNew } from '@ftbpro/mm-admin-ui-components';
import React, { useEffect, useMemo, useState } from 'react';
import { getUserDetails } from '../../../../../../../../../core/utils/user.utils';
import { getColumnsDefinition } from './CampaignsListTableColumnsDefinition';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { tonicCampaigns } from '../../../SettingsAndTarget/components/Target/components/SearchCampaignContainer/components/TonicCampaign/tonicCampaign.selector';
import { campaignsTableState, campaignsDraftTriggeredState, CampaignListItem, } from '../../../../createCampaignState/createCampaign.selector';
import { userConfiguration } from '../../../../createCampaignState/user.selector';
import { css } from '@emotion/react';
import { generateChecksum } from '../../../../../../../../../core/utils/utils';
import { BulkOperations } from './components/BulkOperations/BulkOperations';
import { getLocalStorageData, setLocalStorageData } from '@ftbpro/mm-admin-core-utils';
import { campaignData } from '../../../SettingsAndTarget/components/Target/targetState/target.selector';
import { searchState } from '../../../SettingsAndTarget/components/Target/components/SearchCampaignContainer/searchCampaign.atom';


const styles = {
  wrapper: css({
    padding: '24px',
  }),
  loaderContainer: css({
    width: '100%',
    height: '191px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  noDataWrapper: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    backgroundColor: '#FFF',
  }),
  tableWrapper: css({
    position: 'relative',
  }),
  snackBar: css({
    position: 'absolute',
    top: '-55px',
    right: '0px',
    width: '100%',
  }),
};

const CampaignsListTableComponent = () => {
  const tonicCampaignsList = useRecoilValueLoadable(tonicCampaigns).contents;
  const countries = useRecoilValueLoadable(campaignData).contents?.countries?.facebook;
  const userDefaultConfiguration = useRecoilValueLoadable(userConfiguration).contents;
  const triggeredState = useRecoilValue(campaignsDraftTriggeredState);
  const { loading, triggered } = triggeredState;
  const [campaignsListState, setCampaignsListState] = useRecoilState(campaignsTableState);
  const { campaignsList } = campaignsListState;
  const { isSearchCampaign } = useRecoilValue(searchState);
  const userDetails = useMemo(() => getUserDetails(), []);
  const isExternalUser = userDetails['roles'].includes('externalFBMedia');
  const editableData: any = campaignsList.map((item: RowData) => ({ ...item }));
  const [selectedCampaignsIds, setSelectedCampaignsIds] = useState<number[]>([]);
  const storedColumns = JSON.parse(getLocalStorageData('createDraftsColumnState') ?? '[]');

  const saveDataToLocalStorage = (data: string[]) => setLocalStorageData('createDraftsColumnState', JSON.stringify(data));

  useEffect(() => {
    if (loading) {
      setSelectedCampaignsIds([]);
    }
  }, [loading]);

  const onDeleteClick = (id: number) => {
    setCampaignsListState((prevState) => (
      {
        ...prevState,
        campaignsList: prevState.campaignsList.filter((row: RowData) => row.id !== id)
      }));
    setSelectedCampaignsIds((prevSelectedIds) =>
      prevSelectedIds.filter((selectedCampaignId) => selectedCampaignId !== id)
    );
  };


  const updateCellValue = (newValue: string | number, field: string, data: RowData) => {
    const updatedRow = newValue
      ? { ...data, [field]: ['freeTextName', 'identifier', 'campaignOffer'].includes(field) ? newValue : Number(newValue) }
      : data;
    const newChecksum = generateChecksum(updatedRow);
    const updatedRowWithChecksum = { ...updatedRow, checksum: newChecksum } as CampaignListItem;
    setCampaignsListState((prevState) => (
      {
        ...prevState,
        campaignsList: prevState.campaignsList.map((row: CampaignListItem) => row.id === data.id ? updatedRowWithChecksum : row),
      }));
  };

  const onCellValueChanged = (params: any) => {
    updateCellValue(params.newValue, params.colDef.field, params.data);
  };

  const onSelectedCheckboxesChanged = (data: RowData[]) => {
    setSelectedCampaignsIds(data.map(campaign => campaign.id));
  };

  const columnsDefinition = getColumnsDefinition({ isExternalUser, tonicCampaignsList, onDeleteClick, onCellValueChanged, userDefaultConfiguration, isSearchCampaign, storedColumns, countries });

  return (
    <>
      {triggered ? (
        <div css={styles.wrapper}>
          {loading ? (
            <div css={styles.loaderContainer}>
              <Loader size={Loader.LOADER_SIZES.HUGH} />
            </div>
          ) : campaignsList.length === 0 ? (
            <div css={styles.noDataWrapper}><NoData /></div>
          ) : (
            <div css={styles.tableWrapper}>
              <TableNew
                data={editableData}
                columnsDefinition={columnsDefinition}
                defaultColDef={{ minWidth: 130 }}
                pagination
                onSelectedCheckboxesChanged={onSelectedCheckboxesChanged}
                onColumnsSelectionChange={saveDataToLocalStorage}
                additionalHeaderComponent={(
                  <BulkOperations
                    selectedCampaignsIds={selectedCampaignsIds}
                    updateCellValue={updateCellValue}
                    onDeleteClick={onDeleteClick}
                    userDefaultConfiguration={userDefaultConfiguration}
                  />)}
                suppressRowClickSelection
              />
            </div>
          )}
        </div>) : null}</>
  );
};

export const CampaignsListTable = React.memo(CampaignsListTableComponent);
