/** @jsxImportSource @emotion/react */
import { Dropdown, DropdownListItemProps, FontWeight, Text } from "@ftbpro/mm-admin-ui-components";
import { Card } from "../../../Card/Card";
import { css } from "@emotion/react";
import { useRecoilState, useRecoilValueLoadable, useRecoilValue } from 'recoil';
import { campaignData } from "../../targetState/target.selector";
import { targetState } from "../../targetState/target.atom";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { useEffect, useState } from "react";
import { isDuplicateState } from '../../../Settings/components/DuplicateCampaigns/duplicate.state';


const styles = {
  card: css({
    gap: '8px'
  })
}
export const TargetCountries = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const [countriesOptions, setCountriesOptions] = useState<{ content: string, value: string }[]>([]);
  const { language, isLanguageSet, countries } = target;
  const data = useRecoilValueLoadable(campaignData);
  const isDuplicate = useRecoilValue(isDuplicateState);

  useEffect(() => {
    getCountries();
  }, [data.state, isLanguageSet, language]);

  useEffect(() => {
    if (data.state === 'hasValue' && target.countries.length === 0) {
      const userDefaultCountry = data.contents.defaults?.countries[0];
      const countriesLanguages = data.contents.countries_languages;
      setDefaultCountry(countriesLanguages, countriesOptions, userDefaultCountry);
    }
  }, [countriesOptions]);

  const filterCountriesByLanguage = (countries: Record<string, string>, countriesLanguages: Record<string, string>) => {
    return Object.keys(countries)
      .filter((country) => countriesLanguages[country] === language)
      .map((country) => ({ value: country, content: countries[country] }));
  }

  const getCountries = () => {
    if (data.state === 'hasValue') {
      const countries = data.contents.countries.facebook;
      let countriesOptionsList: { content: string; value: string }[] = [];
      if (isLanguageSet && !isDuplicate) {
        const countriesLanguages = data.contents.countries_languages;
        countriesOptionsList = filterCountriesByLanguage(countries, countriesLanguages);
      } else {
        countriesOptionsList = Object.keys(countries).map((country) => ({ value: country, content: countries[country] }));
      }
      setCountriesOptions(countriesOptionsList);
    }
  }

  const setDefaultCountry = (countriesLanguages: Record<string, string>, countriesOptions: { content: string, value: string }[], userDefaultCountry?: string, ) => {
    let defaultCountry: string[] = [];
    if (countriesOptions.length === 1) {
      defaultCountry = [countriesOptions[0].value];
    } else if (userDefaultCountry && countriesLanguages[userDefaultCountry] === language) {
      defaultCountry = [userDefaultCountry];
    } else if (language === 'US') {
      defaultCountry = ['US'];
    }
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        countries: defaultCountry
      }
    });
  }

  const onCountryChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setTarget((prevTarget) => {
      const copyCountries = prevTarget.countries ? [...prevTarget.countries] : [];
      return {
        ...prevTarget,
        countries: [...copyCountries, selectedItem.value as string]
      }
    });
  }

  const onCountryDelete = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        countries: prevTarget.countries.filter((country) => country !== selectedItem.value)
      };
    });
  };

  const onCountriesSelectAll = (e: ClickEventType<HTMLElement>, selectedItems: DropdownListItemProps[]) => {
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        countries: selectedItems.map((item) => item.value as string)
      };
    });
  };

  const searchPredicate = (query: string | number, item: DropdownListItemProps) => {
    const stringQuery = `${query}`;
    const content = item.content as string;
    const value = item.value as string;
    return content?.toLocaleLowerCase().includes(stringQuery.toLocaleLowerCase()) || value?.toLocaleLowerCase().includes(stringQuery.toLocaleLowerCase());
  };

  return (
    <Card style={styles.card}>
      <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium}>Countries</Text>
      <Dropdown
        items={countriesOptions}
        loading={data.state === 'loading'}
        search
        placeholder="Select Countries"
        onSelectedChange={onCountryChange}
        multiSelection
        searchPredicate={searchPredicate}
        selectAllLabel="Select All"
        showCheckboxes
        expandableChips
        onValueRemove={onCountryDelete}
        onSelectAllChange={onCountriesSelectAll}
        selectedValues={countries}
      />
    </Card>
  );
}
