/** @jsxImportSource @emotion/react */
import { FontWeight, RadioGroup, Text } from "@ftbpro/mm-admin-ui-components";
import { Card } from "../../../Card/Card";

export const PLATFORM_OPTIONS = [
  {
    label: 'Facebook',
    value: 'facebook',
  },
];

const styles = {
  label: {
   marginBottom: '8px'
  }
}

export const Platform = () => {
  return (
    <Card>
      <Text
        type={Text.TEXT_TYPES.PARAGRAPH_L}
        fontWeight={FontWeight.Medium}
        style={styles.label}
      >
        Platform
      </Text>
      <RadioGroup
        items={PLATFORM_OPTIONS}
        defaultCheckedValue={'facebook'}
        disabled
      />
    </Card>
  );
}
