/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';
import { Divider } from '@ftbpro/mm-admin-ui-components';

interface CardProps {
  children: ReactNode;
  style?: any;
}

const styles = {
  card: {
    padding: '16px 26px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
}
export const Card = ({ children, style }: CardProps) => {

  return (
    <>
      <div css={[styles.card, style]}>
        {children}
      </div>
      <Divider />
    </>
  );
}
