import { atom } from "recoil";
import { SNACK_BAR_STATUSES } from "@ftbpro/mm-admin-ui-components";

interface CampaignMessageState {
  open: boolean, message: string, status?: SNACK_BAR_STATUSES, stayOpen?: boolean
};


export const campaignMessageState = atom<CampaignMessageState>({
  key: 'campaignMessageState ',
  default: { open: false, message: '', status: SNACK_BAR_STATUSES.ERROR, stayOpen: false }
});
