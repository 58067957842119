import { useEffect } from "react";
import { atom, useSetRecoilState } from "recoil";


export interface RootState {
  adsetsDuplication?: {
    type: string;
    provider?: string;
    adsets: { name: string }[]
  }
}
export const rootState = atom< RootState>({
  key: 'rootState',
  default: {} as  RootState,
});


export function InitializeRecoilState({ adsetsDuplication }: RootState) {
  const setRootState = useSetRecoilState(rootState);

  useEffect(() => {
    if (adsetsDuplication) {
      setRootState({ adsetsDuplication });
    }
  }, [adsetsDuplication, setRootState]);


  return null;
}
