import React, { useEffect, useState } from 'react';
import { RecoilRoot } from 'recoil';
import { AppRoutes } from './components/AppRoutes/AppRoutes';
import { InitializeRecoilState } from './InitializeRecoilState';

function App() {
  const [isToken, setIsToken] = useState(false);
  const [adsetsDuplication, setAdsetsDuplication] = useState();

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data?.messageType === 'userDetails') {
        const isToken = !!event.data?.token;
        if (isToken) {
          localStorage.setItem('auth_app_token', btoa(JSON.stringify(event.data.token)));
          localStorage.setItem('auth_app_user', event.data?.user);
          if (event.data?.adsetsDuplication) {
            setAdsetsDuplication(JSON.parse(atob(event.data?.adsetsDuplication)));
          }
          setIsToken(true);
        }
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <RecoilRoot >
      <InitializeRecoilState adsetsDuplication={adsetsDuplication} />
      {!isToken ? <div>No Token Provided</div> :
        <AppRoutes />
      }
    </RecoilRoot>
  );
}

export default App;
