import { FontWeight, InputWithChips, Text } from "@ftbpro/mm-admin-ui-components"
import { useRecoilState } from "recoil";
import { targetState } from "../../../../targetState/target.atom";
import { SyntheticEvent, useState } from "react";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { AdsOptimization } from "../../../../../../../../createCampign.typs";

export const RoasGoal = () => {

  const [target, setTarget] = useRecoilState(targetState);
  const selectedValues = target.roas?.map(roasGoal => roasGoal.toString()) || [];
  const [error, setError] = useState('');
  const showRoas = (target.adsOptimization as any)?.includes(AdsOptimization.Value); 

  const setSelectedValues = (values: number[]) => {
    setTarget((prevTarget) => ({
      ...prevTarget,
      roas: values,
    }));
  }

  const validateRoasValue = (value: string) => {
    if (isNaN(Number(value))) {
      return 'Bid amount must be a number.';
    }
    if (Number(value) < 0) {
      return 'Bid amount must be at least 0';
    }
    if (Number(value) > 1000) {
      return 'Bid amount must not exceed 1000.';
    }
    return '';
  }

  const onROASAdd = (e: SyntheticEvent<HTMLElement, Event>, values: string[]) => {
    const newValue = values[values.length - 1];
    const validateValue = validateRoasValue(newValue as string);
    if (validateValue !== '') {
      setError(validateValue);
      return;
    }
    setError('');
    setSelectedValues(values.map(value => Number(value)));
  }

  const onROASDelete = (e: ClickEventType, chip: string) => {
    const newValues = selectedValues.filter(value => value !== chip);
    setSelectedValues(newValues.map(value => Number(value)));
  }

  return (
    showRoas &&
    <div>
      <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium}>ROAS (use a number between 0 and 1000)</Text>
      <InputWithChips
        onChipsAdd={onROASAdd}
        onChipRemove={onROASDelete}
        selectedValues={selectedValues}
        error={error}
      />
    </div >
  )
}
