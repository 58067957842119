/** @jsxImportSource @emotion/react */
import { Dropdown, DropdownListItemProps, FontWeight, Text, TextButton, } from "@ftbpro/mm-admin-ui-components"
import { Card } from "../../../Card/Card"
import { SyntheticEvent, useMemo, useState } from "react";
import { debounce } from "../../../../../../../../../../../core/utils/utils";
import { CampaignsServiceProvider } from "../../../../../../../../../../../services/campaigns/CampaignsServiceProvider";
import { targetState } from "../../targetState/target.atom";
import { useRecoilState } from "recoil";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { FBInterest } from "../../../../../../createCampign.typs";

const styles = {
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  container: {
    gap: '8px',
  }
}

export const DetailedTarget = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const selectedInterests = target.interests || [];
  const interestSearch = target.allInterestSearch || [];
  const [loading, setLoading] = useState(false);
  const interestSearchList = useMemo(() => interestSearch.map((item: any) => ({ value: item.id, content: item.name })), [interestSearch]);

  const onSearchQueryChange = async (e: SyntheticEvent<Element, Event>, searchQuery: string) => {
    if (searchQuery.length >= 3) {
      setLoading(true);
      const response = await CampaignsServiceProvider.getFbInterestSearch(searchQuery);
      setTarget((prevTarget) => ({
        ...prevTarget,
        allInterestSearch: response
      }));
      setLoading(false);
    }
  };

  const onSuggestionsClick = async () => {
    if (selectedInterests.length > 0) {
      const terms = selectedInterests.map(interest => interest.name);
      setLoading(true);
      const response = await CampaignsServiceProvider.getFbInterestSuggestions({ terms });
      setTarget((prevTarget) => ({
        ...prevTarget,
        interests: response
      }));
      setLoading(false);
    }
  }

  const getInterestsPath = (interestId: string) => {
    const interest = interestSearch.find((item: FBInterest) => item.id === interestId)?.path;
    return interest || [];
  }

  const onSelectedChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setTarget((prevTarget) => {
      const copyInterests = prevTarget.interests ? [...prevTarget.interests] : [];
      return {
        ...prevTarget,
        interests: [...copyInterests, { id: selectedItem.value as string, name: selectedItem.content as string, path: getInterestsPath(selectedItem.value as string) }]
      }
    });
  }

  const onDeleteInterest = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        interests: prevTarget.interests?.filter((interest) => interest.id !== selectedItem.value)
      }
    });
  }

  const onSelectAllChange = (e: ClickEventType<HTMLElement>, selectedItems: DropdownListItemProps[]) => {
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        interests: selectedItems.map((item) => ({ id: item.value as string, name: item.content as string, path: getInterestsPath(item.value as string) }))
      }
    });
  }


 const renderSelectedValue = (item: DropdownListItemProps) => {
   const path = getInterestsPath(item.value as string);
   return path.join(' > ');
 };


  return (
    <Card style={styles.container}>
      <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium}>Detailed Target (not part of template)</Text>
      <Dropdown
        placeholder="Add audience traits"
        multiSelection
        showCheckboxes
        search
        expandableChips
        onSearchQueryChange={debounce(onSearchQueryChange, 300)}
        items={interestSearchList}
        loading={loading}
        searchPredicate={(() => true)}
        selectAllLabel="Select All"
        onSelectedChange={onSelectedChange}
        onValueRemove={onDeleteInterest}
        onSelectAllChange={onSelectAllChange}
        renderSelectedValue={renderSelectedValue}
        selectedValues={selectedInterests?.map(interest => interest.id)}
        labelProps={{
          text: 'Type 3 characters to search',
        }}
      />
      <div css={styles.button}>
        <TextButton content={'Suggestions'}
          onClick={onSuggestionsClick}
        />
      </div>
    </Card>
  )
}
