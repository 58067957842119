import { selector } from 'recoil';
import { ConfigurationsServiceConfigsProvider } from '../../../../../../services/configurations/ConfigurationsServiceConfigsProvider';
import { getUserDetails } from '../../../../../../core/utils/user.utils';
import { Group } from '../../../../../groups/groups.types';
import { GroupType } from '../../../../../groups/groups.enums';

const getItemsByPlatform = (groupsList: Group[]) => {
  const platforms = ['facebook'];
  const userItemsByPlatform = platforms.reduce((userItems: Record<string, string[]>, platform: string) => {
    userItems[platform] = [];
    return userItems;
  }, {});
  groupsList.forEach(({ platform, items = [] }) => {
    if (userItemsByPlatform[platform]) {
      items.forEach(item => {
        if (!userItemsByPlatform[platform].includes(item)) {
          userItemsByPlatform[platform].push(item);
        }
      });
    }
  });
  return userItemsByPlatform;
};

export const userConfiguration = selector({
  key: 'userConfigurationSelector',
  get: async () => {
    try {
      const response = await ConfigurationsServiceConfigsProvider.getConfigurations(true);
      return response.configs[0] || null;
    } catch (error) {
      const errorMessage = 'There was an error fetching user preferences';
      throw new Error(errorMessage);
    }
  },
});

export const userDataState = selector({
  key: 'userDataSelector',
  get: () => {
    const data = getUserDetails();
    const userGroupsList = data?.['groups'] || [];
    const userDomainGroups = userGroupsList.filter((group: Group) => group.type === GroupType.Domain);
    const userDomains = getItemsByPlatform(userDomainGroups);
    const userAccountGroups = userGroupsList.filter((group: Group) => group.type === GroupType.AdAccount);
    const userAccounts = getItemsByPlatform(userAccountGroups);
    return {
      userDomains,
      userAccounts
    };
  },
});
