/** @jsxImportSource @emotion/react */
import { Button, ButtonType, Dropdown, DropdownListItemProps, Loader } from "@ftbpro/mm-admin-ui-components"
import { templateState, templateUsersRefreshTrigger } from "../../../../../campaignsTemplate.atom";
import { useRecoilValueLoadable, useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { getTemplateUsers, getUsers } from "../../../../../campaignsTemplate.selector";
import { CampaignsServiceProvider } from "../../../../../../../../../../../../../../../../services/campaigns/CampaignsServiceProvider";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { css } from "@emotion/react";
import { campaignMessageState } from "../../../../../../../../../../CreateCampaignMessage/createCampaignMessage.atom";
import { DeleteModal } from "../DeleteModal/DeleteModal";

const styles = {
  share: css({
    display: 'flex',
    justifyContent: 'right',
    paddingTop: '6px'
  })
}

interface ShareUsersProps {
  closeShare: () => void, 
  templateOwnerId?: string
}

export const ShareUsers = ({ closeShare, templateOwnerId }: ShareUsersProps) => {

  const template = useRecoilValue(templateState);
  const selectedTemplate = template?.selectedTemplate;
  const users = useRecoilValueLoadable(getUsers);
  const templateUsers = useRecoilValueLoadable(getTemplateUsers(selectedTemplate?.id));
  const [templateUsersSelected, setTemplateUsersSelected] = useState<{ value: string, content: string }[]>([]);
  const [showShareLoader, setShowShareLoader] = useState(false);
  const setTemplateUsersRefreshTrigger = useSetRecoilState(templateUsersRefreshTrigger);
  const setError = useSetRecoilState(campaignMessageState);
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    const usersList = getUserItems();
    if (templateUsers.state === 'hasValue' && Array.isArray(templateUsers.contents?.userIDs) && usersList.length > 0) {
      const templateUserIds = templateUsers.contents?.userIDs;
      const userNames = usersList.filter(user => templateUserIds.includes(user.value));
      setTemplateUsersSelected(userNames);
    }
  }, [templateUsers.state, users.state, selectedTemplate]);

  useEffect(() => {
    if (templateUsers.state === 'hasError') {
      setError({ open: true, message: 'Error fetching template users' });
      closeShare();
    }
    if (users.state === 'hasError') {
      setError({ open: true, message: 'Error fetching users' });
      closeShare();
    }
  }, [templateUsers.state, users.state]);


  const getUserItems = () => {
    if (users.state === 'hasValue' && Array.isArray(users.contents?.users)) {
      return users.contents?.users?.filter((user: any) => user.roles.includes('externalFBMedia') || user.roles.includes('facebook_mediabuying'))
        .map((user: any) => ({ value: user.id, content: user.name }));
    }
    return [];
  }

  const onSelectedUserChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    const updatedUsers = [...templateUsersSelected, { value: selectedItem.value as string, content: selectedItem.content as string }]
    setTemplateUsersSelected(updatedUsers)
  }

  const onUserDelete = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    if(templateOwnerId ===  selectedItem.value){
      setOpenModal(true);
    } else {
      deleteUser(selectedItem.value as string);
    }
  }

  const deleteUser = (userId: string) => {
    const updatedUsers = templateUsersSelected.filter(user => user.value !== userId);
    setTemplateUsersSelected(updatedUsers);
  }

  const onSelectedAll = (e: ClickEventType<HTMLElement>, selectedItems: DropdownListItemProps[]) => {
    const updatedUsers = selectedItems.map(user => ({ content: user.content as string, value: user.value as string }));
    setTemplateUsersSelected(updatedUsers);
  }

  const shareUser = async () => {
    setShowShareLoader(true)
    try {
      await CampaignsServiceProvider.updateTemplateUsers(selectedTemplate?.id as string, templateUsersSelected.map(user => user.value));
      setShowShareLoader(false);
      setTemplateUsersRefreshTrigger((prev) => prev + 1)
      closeShare();
    } catch {
      setShowShareLoader(false);
      setError({ open: true, message: 'Error sharing users' });
      closeShare();
    }
  }

  const searchPredicate = (query: string | number, item: DropdownListItemProps) => {
    const stringQuery = `${query}`;
    const value = item.content as string;
    return value?.toLocaleLowerCase().includes(stringQuery.toLocaleLowerCase());
  };

  return (
    <div>
      <Dropdown
        placeholder={'Select'}
        items={getUserItems()}
        labelProps={{
          text: 'Choose Users',
        }}
        multiSelection
        showCheckboxes
        expandableChips
        selectAllLabel="Select All"
        search
        loading={templateUsers.state === 'loading' || users.state === 'loading'}
        selectedValues={templateUsersSelected.map(user => user.value)}
        onSelectedChange={onSelectedUserChange}
        onSelectAllChange={onSelectedAll}
        onValueRemove={onUserDelete}
        searchPredicate={searchPredicate}
      />
      <div css={styles.share}>
        {
          showShareLoader ?
            <Loader />
            :
            <Button
              content="Share"
              onClick={shareUser}
              type={ButtonType.Secondary}
            />
        }
      </div>
      <DeleteModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onDelete={ () => deleteUser(templateOwnerId || '')}
      />
    </div>
  )
}

