import { atom, selector } from 'recoil';
import { rootState } from '../../../../../../../../../../../InitializeRecoilState';
import { CampaignType } from '../../../../../../createCampign.typs';

export const selectedDuplicateCampaignsDefault = selector<string[]>({
  key: 'selectedDuplicateCampaignsDefault',
  get: ({ get }) => {
    const duplicateAdsets = get(rootState);
    return duplicateAdsets.adsetsDuplication?.adsets.map(adset => adset.name) || [];
  },
});

export const selectedDuplicateCampaigns = atom<string[]>({
  key: 'selectedDuplicateCampaigns',
  default: selectedDuplicateCampaignsDefault
});

export const useOriginalPostIDState = atom<boolean>({
  key: 'useOriginalPostIDState',
  default: true,
});

export const isDuplicateState = atom({
  key: 'isDuplicateState',
  default: false,
});

export const duplicateData = selector({
  key: 'duplicateDataSelector',
  get: ({ get }) => {
    const rootAtomState = get(rootState);
    const { adsetsDuplication } = rootAtomState;
    if (!adsetsDuplication) {
      return {};
    }
    const duplicateCampaigns = adsetsDuplication?.adsets?.map((campaign: { name: string }) => campaign.name) || [];
    const searchProvider = adsetsDuplication?.provider;
    const isSearch =  adsetsDuplication.type === CampaignType.Search;
    const useOriginalPostID = !isSearch;
    return {
      duplicateCampaigns,
      searchProvider,
      isSearch,
      useOriginalPostID
    };
  },
  set: ({ set }, newValue) => {
    if (typeof newValue === 'object' && 'useOriginalPostID' in newValue) {
      set(useOriginalPostIDState, (newValue.useOriginalPostID) as boolean);
    }
  },
});
