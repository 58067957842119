import { atom } from 'recoil';
import { SearchProvider } from '../createCampign.typs';

export type ServerSearch = {
  searchProvider: SearchProvider,
  exploreAds?: {
    campaignOffer: string,
    identifier: string,
  }
  googleDirect?: {
    identifier: string,
  }
  tonic?: {
    campaignId: string,
  }
}

export type CampaignListItem = {
  id: number,
  checksum?: number,
  articleId: string,
  audiences: {
    audienceId: string,
    type: string,
    name: string,
  }
  defaultBid?: number,
  defaultDailyBudget?: number,
  maxBid?: number,
  bidStrategy: string,
  browser: string,
  conversionEvent: string,
  country: string,
  customPixel: string,
  device: string,
  dynamicCreative: boolean,
  freeTextName: string,
  gender: string,
  interest: {
    category: string,
    id: string,
    name: string,
  }
  isSearch: boolean,
  languageSet: boolean,
  minBid: number,
  objective: string,
  optimizationGoal: string,
  os: string,
  owner: string,
  pausedCampaign: boolean,
  roas: number | null,
  startOn: string,
  search: ServerSearch,
  searchProvider?: SearchProvider,
  campaignOffer?: string,
  identifier?: string,
}

export const campaignsTableState = atom({
  key: 'campaignsTableState',
  default: { campaignsList: [] as CampaignListItem[]},
});

const campaignsDraftTriggeredInitialState = {
  triggered: false,
  loading: false,
};

export const campaignsDraftTriggeredState = atom({
  key: 'campaignsDraftTriggered',
  default: campaignsDraftTriggeredInitialState,
});

