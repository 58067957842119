/** @jsxImportSource @emotion/react */
import React, { Dispatch, SetStateAction } from 'react';
import { Card, Dropdown, Input, RequiredIndicatorType, Text, Types } from '@ftbpro/mm-admin-ui-components';
import { PlatformsType } from '../../../../../core/enums/enums';
import { css } from '@emotion/react';
import { SelectGroupType } from '../selectGroupType/SelectGroupType';
import { ActivityType, GroupType } from '../../../groups.enums';
import { groupTypedItem } from '../../../groups.types';

export const styles = {
  nameAndPlatformColumns: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '24px',
  }),
  typeAndActivityColumns: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '24px',
    backgroundColor: '#ffffff',
    marginBottom: '24px',
    paddingRight: '16px',
  }),
  card: {
    boxShadow: 'none',
  },
};

const variables = {
  card: {
    cardWidth: '100%',
    cardContentOverflow: 'visible',
    cardDividerColor: 'none',
    cardHeaderPadding: '8px 0px 0px 16px',
    cardContentPadding: '16px 16px 0px 16px'
  },
  dropdown: {
    dropdownWidth: '100%',
  },
};

interface GeneralDataProps {
  groupName: string;
  setGroupName: Dispatch<SetStateAction<string>>;
  selectedPlatform: string;
  setSelectedPlatform: Dispatch<SetStateAction<string>>;
  setSelectedGroupTypedItemList: Dispatch<SetStateAction<groupTypedItem[]>>;
  selectedGroupType: GroupType;
  setSelectedGroupType: Dispatch<SetStateAction<GroupType>>;
  selectedActivity: string;
  setSelectedActivity: Dispatch<SetStateAction<string>>;
  isEditing: boolean;
}

export const GeneralData = (props: GeneralDataProps) => {
  const {
    groupName,
    setGroupName,
    selectedPlatform,
    setSelectedPlatform,
    setSelectedGroupTypedItemList,
    selectedGroupType,
    setSelectedGroupType,
    selectedActivity,
    setSelectedActivity,
    isEditing,
  } = props;

  return (
    <>
      <Card
        title="Name & Platform"
        textType={Text.TEXT_TYPES.TITLE_S}
        variables={variables.card}
        style={styles.card}
      >
        <div css={styles.nameAndPlatformColumns}>
          <Input
            clearable
            onClear={() => {
              setGroupName('');
            }}
            labelProps={{
              text: 'Name',
              requiredIndicator: isEditing ? RequiredIndicatorType.None : RequiredIndicatorType.Required,
            }}
            placeholder="My new group"
            onChange={(e: React.SyntheticEvent<HTMLElement, Event>) => {
              const inputElement = e.target as HTMLInputElement;
              console.log('inputElement.value', inputElement.value);
              setGroupName(inputElement.value);
            }}
            value={groupName}
            autoHeight
            readOnly={isEditing}
          />
          <Dropdown
            placeholder={'Choose Platform'}
            readOnly={isEditing}
            items={Object.values(PlatformsType)}
            selectedValue={selectedPlatform}
            labelProps={{
              requiredIndicator: isEditing ? RequiredIndicatorType.None : RequiredIndicatorType.Required,
              text: 'Platform',
            }}
            search
            variables={variables.dropdown}
            onSelectedChange={(e: Types.ClickEventType<HTMLElement>, platform: any) => {
              setSelectedGroupTypedItemList([]);
              setSelectedPlatform(platform.value.toLowerCase());
            }}
          />
        </div>
      </Card>
      <div css={styles.typeAndActivityColumns}>
        <SelectGroupType selectedGroupType={selectedGroupType}
                         setSelectedGroupTypedItemList={setSelectedGroupTypedItemList}
                         setSelectedGroupType={setSelectedGroupType} isEditing={isEditing}/>

        {selectedGroupType === GroupType.Domain &&
         <Dropdown
           placeholder={'Select Activity'}
           items={Object.values(ActivityType)}
           selectedValue={selectedActivity}
           style={{ paddingBottom: '28px' }}
           labelProps={{
             requiredIndicator: RequiredIndicatorType.Optional,
             text: 'Activity',
           }}
           search
           variables={variables.dropdown}
           onSelectedChange={(e: Types.ClickEventType<HTMLElement>, activity: any) => {
             setSelectedActivity(activity.value);
           }}
         />}
      </div>
    </>
  );
};
