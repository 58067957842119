/** @jsxImportSource @emotion/react */
import { FontWeight, InputWithChips, Text, Types } from "@ftbpro/mm-admin-ui-components";
import { Card } from "../../../Card/Card";
import { SyntheticEvent, useState } from "react";
import { useRecoilState } from "recoil";
import { targetState } from "../../targetState/target.atom";
import { BidStrategy } from "../../../../../../createCampign.typs";


const styles = {
  container: {
    gap: '8px'
  }
}
export const BidAmount = () => {

  const [target, setTarget] = useRecoilState(targetState);
  const selectedValues = target.bidAmount?.map(bid => bid.toString()) || [];
  const [error, setError] = useState('');
  const showBidAmount = target.bidStrategy?.includes(BidStrategy.BidCap) || target.bidStrategy?.includes(BidStrategy.CostCap)

  const setSelectedValues = (values: number[]) => {
    setTarget((prevTarget) => ({
      ...prevTarget,
      bidAmount: values,
    }));
  }



  const onChipAdd = (e: SyntheticEvent<HTMLElement, Event>, values: string[]) => {
    const newValue = values[values.length - 1];
    const validateValue = validateChipValue(newValue as string);
    if (validateValue !== '') {
      setError(validateValue);
      return;
    }
    setError('');
    setSelectedValues(values.map(value => Number(value)));
  }



  const onChipRemove = (e: Types.ClickEventType, chip: string) => {
    const newValues = selectedValues.filter(value => value !== chip);
    if(newValues.length === 0) {
      newValues.push('0.5');
    }
    setSelectedValues(newValues.map(value => Number(value)));
  }

  const validateChipValue = (value: string) => {
    const lengthNumbersAfterDot = value.split('.').pop()?.length;
    if (isNaN(Number(value))) {
      return 'Bid amount must be a number.';
    }
    if (Number(value) < 0.01) {
      return 'Bid amount must be at least 0.01';
    }
    if (Number(value) > 9) {
      return 'Bid amount must not exceed 9.';
    }
    if (lengthNumbersAfterDot && lengthNumbersAfterDot > 2) {
      return 'Bid amount must have only one decimal point.';
    }
    return '';
  }

  return (
    showBidAmount ?
      <Card style={styles.container}>
        <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium}>Bid Amount: (insert a number and press enter)</Text>
        <InputWithChips
          onChipsAdd={onChipAdd}
          onChipRemove={onChipRemove}
          selectedValues={selectedValues}
          error={error}
        />
      </Card>
      : null
  );
}
