import { Dropdown, DropdownListItemProps } from "@ftbpro/mm-admin-ui-components"
import { useRecoilState } from "recoil";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { AdsOptimization, Objective, TrafficOptimization } from "../../../../../../../../createCampign.typs";
import { targetState } from "../../../../targetState/target.atom";

const optimizationAdsItems = [
  { content: 'Conversions', value: AdsOptimization.Conversions },
  { content: 'Value', value: AdsOptimization.Value },
]

const trafficOptimizationItems = [
  { content: 'Link Clicks', value: TrafficOptimization.LinkClicks },
  { content: 'Impressions', value: TrafficOptimization.Impressions },
  { content: 'Daily Unique Reach', value: TrafficOptimization.DailyUniqueReach },
  { content: 'Landing Page Views', value: TrafficOptimization.LandingPageViews },
]

export const DeliveryAdsOptimization = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const { adsOptimization, objective } = target;


  const onAdsOptimizationChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setTarget((prevTarget) => {
      const copyAdsOptimization = [...prevTarget.adsOptimization];
      return {
        ...prevTarget,
        adsOptimization: [...copyAdsOptimization, selectedItem.value ] as AdsOptimization[] | TrafficOptimization[]
      }
    });
  }

  const onAdsOptimizationSelectAll = (e: ClickEventType<HTMLElement>, selectedItems: DropdownListItemProps[]) => {
    const selectedValues = selectedItems.map((item) => item.value);
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        adsOptimization: selectedValues as AdsOptimization[] | TrafficOptimization[]
      }
    });
  }

  const onAdsOptimizationDeleted = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setTarget((prevTarget) => {
      const updatedAdsOptimization = (prevTarget.adsOptimization as any)?.filter((adsOptimization: unknown) => adsOptimization !== selectedItem.value);
      return {
        ...prevTarget,
        adsOptimization: updatedAdsOptimization as AdsOptimization[] | TrafficOptimization[]
      }
    });
  }

  return (
    <Dropdown
      items={objective === Objective.Traffic ? trafficOptimizationItems :  optimizationAdsItems}
      selectAllLabel={'Select All'}
      multiSelection
      showCheckboxes
      placeholder="Optimization for Ad Delivery"
      selectedValues={adsOptimization as string[]}
      onValueRemove={onAdsOptimizationDeleted}
      onSelectAllChange={onAdsOptimizationSelectAll}
      onSelectedChange={onAdsOptimizationChange}
    />
  )
}
