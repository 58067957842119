/** @jsxImportSource @emotion/react */
import { Button, CssTooltip, RegularTabs, SNACK_BAR_STATUSES, TabData, TOOLTIP_POSITIONS } from '@ftbpro/mm-admin-ui-components';
import { Settings } from "./components/Settings/Settings";
import { Target } from "./components/Target/Target";
import { css } from "@emotion/react";
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { validateCampaign } from "./settingsAndTarget.validations";
import { settingsState } from "./components/Settings/settingsState/settings.atom";
import { targetState } from "./components/Target/targetState/target.atom";
import { campaignData } from "./components/Target/targetState/target.selector";
import { formatCampaignsDraftsForClient, formatCampaignToServer } from './settingsAndTarget.formatter';
import { campaignsDraftTriggeredState, campaignsTableState } from '../../createCampaignState/createCampaign.selector';
import { CampaignsServiceProvider } from '../../../../../../../services/campaigns/CampaignsServiceProvider';
import { campaignMessageState } from '../CreateCampaignMessage/createCampaignMessage.atom';
import { TooltipContent } from '../../../../../../../components/TooltipContent/TooltipContent';
import { searchState } from './components/Target/components/SearchCampaignContainer/searchCampaign.atom';

const styles = {
  container: css({
    backgroundColor: 'white',
    position: 'relative',
    overflowY: 'auto',
    zIndex: 2,
    width: '352px',
  }),
  content: css({
    overflowY: 'auto',
    height: 'calc(100vh - 180px)',
    position: 'relative',
  }),
  applyButton: css({
    position: 'fixed',
    bottom: '0',
    zIndex: 3,
    backgroundColor: 'white',
    padding: '16px 26px',
    display: 'flex',
    justifyContent: 'center',
  }),
  tooltip: {
    zIndex: 4,
  }
};

const TABS_VALUES = [
  {
    label: 'Target',
    key: 'target',
  },
  {
    label: 'Settings',
    key: 'settings',
  },
]

export const SettingsAndTarget = () => {
  const setTriggerState = useSetRecoilState(campaignsDraftTriggeredState);
  const [tab, setTab] = useState('target');
  const campaignTarget = useRecoilValue(targetState);
  const campaignSettings = useRecoilValue(settingsState);
  const [campaignsList, setCampaignsList] = useRecoilState(campaignsTableState);
  const {isSearchCampaign} = useRecoilValue(searchState);
  const data = useRecoilValueLoadable(campaignData);
  const [disabledApply, setDisabledApply] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const setMessage = useSetRecoilState(campaignMessageState);

  useEffect(() => {
     if (data.state === 'hasError') {
       setMessage({ open: true, message: 'Error fetching campaign data' });
      }
  }, [data.state]);

  const onTabSelected = (tabData: TabData) => {
    setTab(tabData.key as string);
  }


  const onApplyDraft = async () => {
      try {
        setTriggerState({ triggered: true, loading: true });
        const formattedDataToServer = formatCampaignToServer(campaignTarget, isSearchCampaign);
        const response = await CampaignsServiceProvider.generateCampaignsDraft(formattedDataToServer);
        const drafts = response.drafts;
        const formattedCampaignsDrafts = formatCampaignsDraftsForClient(drafts, campaignsList.campaignsList);
        setCampaignsList({ campaignsList: formattedCampaignsDrafts});
        setTriggerState({ triggered: true, loading: false });
      } catch (error) {
        const errorMessage = 'Error generating campaigns drafts';
        setMessage({ open: true, status: SNACK_BAR_STATUSES.ERROR, message: errorMessage });
        setTriggerState({ triggered: true, loading: false });
      }
  }


  useEffect(() => {
    const errors = validateCampaign(campaignTarget, isSearchCampaign);
    if (errors.length > 0) {
      setErrors(errors);
      setDisabledApply(true);
    } else {
      setErrors([]);
      setDisabledApply(false);
    }
  }, [campaignTarget, campaignSettings, isSearchCampaign])

  return (
    <div css={styles.container} >
      <RegularTabs
        tabs={TABS_VALUES}
        onTabSelected={onTabSelected}
      />
      <div css={styles.content}>
        {tab === 'target' && <Target />}
        {tab === 'settings' && <Settings />}
      </div>
      <div css={styles.applyButton}>
        <CssTooltip
          content={errors.length > 0 ? <TooltipContent messages={errors}/> : ''}
          style={styles.tooltip as any}
          position={TOOLTIP_POSITIONS.RIGHT}
          variables={{
            tooltipMultilineWidth: 'fit-content',
          }}
        >
          <Button
            content="Apply Draft"
            onClick={onApplyDraft}
            variables={{
              width: '300px',
              height: '32px',
            }}
            disabled={disabledApply}
          />
        </CssTooltip>
      </div>
    </div>
  );
}
