/** @jsxImportSource @emotion/react */
import React from 'react';
import { Text } from '@ftbpro/mm-admin-ui-components';
import { ReactComponent as ErrorImage } from '../../core/assets/error-img.svg';
import { css } from '@emotion/react';

const styles = {
  mainContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '180px',
    padding: '24px',
  }),
  contentContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  image: css({
    width: '144px',
    height: '168px',
  }),
  title: css({
    marginTop: '32px',
    height: '28px',
  }),
  description: css({
    height: '40px',
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
};

interface ErrorPageProps {
  title: string,
  description: string,
  message: string,
}

export const ErrorPage = (props: ErrorPageProps) => {
  const { title, description, message } = props;
  return (
    <div css={styles.mainContainer}>
      <div css={styles.contentContainer}>
        <ErrorImage css={styles.image}/>
        <Text css={styles.title} type={Text.TEXT_TYPES.TITLE_XL}>{title}</Text>
        <div css={styles.description}>
          <Text type={Text.TEXT_TYPES.PARAGRAPH_L}>{description}</Text>
          <Text type={Text.TEXT_TYPES.PARAGRAPH_L}>{message}</Text>
        </div>
      </div>
    </div>
  );
};
