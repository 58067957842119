import { validateDefaultBid, validateDefaultDailyBudget, validateMaxBid } from './components/campaignSettings/campaignSettings.validations';

export const validateConfiguration = (configurationName: string, selectedPlatform: string, defaultDailyBudget: string, defaultBid: string, maxBid: string, selectedUsersLength: number) => {
  let errors: string[] = [];

  !configurationName && errors.push('Please ad a configuration name');
  !selectedPlatform && errors.push('Please ad a platform');
  validateDefaultDailyBudget(defaultDailyBudget) && errors.push(validateDefaultDailyBudget(defaultDailyBudget));
  validateDefaultBid(defaultBid, maxBid) && errors.push(validateDefaultBid(defaultBid, maxBid));
  validateMaxBid(maxBid) && errors.push(validateMaxBid(maxBid));
  selectedUsersLength === 0 && errors.push('Please select at least 1 user');

  return errors;
};
