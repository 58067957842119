/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Card } from "../../../Card/Card";
import { Dropdown, Text, FontWeight, TextButton, DropdownListItemProps, Chip } from "@ftbpro/mm-admin-ui-components";
import { useState } from "react";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { useRecoilState } from "recoil";
import { targetState } from "../../targetState/target.atom";

const styles = {
  ageWrapper: css({
    display: 'flex',
    gap: '8px',
    flexDirection: 'row',
    alignItems: 'center',
  }),
  addButton: {
    marginLeft: "8px",
  },
  chipsContainer: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    flexWrap: 'wrap',
    maxWidth: '300px'
  }),
}

export const AgeRange = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const [minAge, setMinAge] = useState('18');
  const [maxAge, setMaxAge] = useState('65 +');
  const selectedAgeRanges = target.ages || [];
  const [disabledAddButton, setDisabledAddButton] = useState(false);

  const getAgeValues = (isMaxAge: boolean) => {
    const ages = [];
    for (let i = 13; i <= 65; i++) {
      if (isMaxAge && i === 65) {
        ages.push(`${i} +`);
      } else {
        ages.push(i.toString());
      }
    }
    return ages;
  }

  const onMinAgeChange = (e: ClickEventType, selectedItem: DropdownListItemProps) => {
    setMinAge(selectedItem.value as string);
    if (Number(selectedItem.value) > Number(maxAge)) {
      setDisabledAddButton(true);
    } else if (disabledAddButton) {
      setDisabledAddButton(false);
    }
  }

  const onMaxAgeChange = (e: ClickEventType, selectedItem: DropdownListItemProps) => {
    setMaxAge(selectedItem.value as string);
    if (Number(selectedItem.value) < Number(minAge)) {
      setDisabledAddButton(true);
    } else if (disabledAddButton) {
      setDisabledAddButton(false);
    }
  }

  const onAddButtonClick = () => {
    if (!target.ages?.includes(`${minAge}-${maxAge}`)) {
      setTarget((prevTarget) => {
        const copySelectedAgeRanges = prevTarget.ages ? [...prevTarget.ages] : [];
        return {
          ...prevTarget,
          ages: [...copySelectedAgeRanges, `${minAge}-${maxAge}`]
        }
      });
    }
  }

  const onRemoveChip = (ageToRemove: string) => {
    const newAgeRanges = selectedAgeRanges.filter(age => age !== ageToRemove);
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        ages: newAgeRanges
      }
    });
  }

  return (
    <Card>
      <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium} >Age Range</Text>
      <div css={styles.ageWrapper}>
        <Dropdown
          items={getAgeValues(false)}
          variables={{ dropdownWidth: "40%" }}
          selectedValue={minAge}
          fluid
          onSelectedChange={onMinAgeChange}
        />
        <Text type={Text.TEXT_TYPES.PARAGRAPH_L} color="#BCB7D2">{" - "}</Text>
        <Dropdown
          items={getAgeValues(true)}
          variables={{ dropdownWidth: "40%" }}
          selectedValue={maxAge}
          onSelectedChange={onMaxAgeChange}
          fluid
        />
        <TextButton
          content="Add"
          style={styles.addButton}
          onClick={onAddButtonClick}
        />
      </div>
      <div css={styles.chipsContainer} >
        {selectedAgeRanges.map((ageRange) => (
          <Chip text={ageRange} icon={Chip.CHIP_ICON.REMOVE}
            onClick={() => onRemoveChip(ageRange)} key={ageRange} />
        ))}
      </div>
    </Card>
  );
}
