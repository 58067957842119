import { Checkbox, Text } from "@ftbpro/mm-admin-ui-components"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Card } from "../../../Card/Card"
import { useRecoilState, useRecoilValue } from 'recoil';
import { targetState } from "../../targetState/target.atom"
import { isDuplicateState } from '../../../Settings/components/DuplicateCampaigns/duplicate.state';


export const TargetLanguage = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const isDuplicate = useRecoilValue(isDuplicateState);
  const isLanguageSet = target.isLanguageSet;
  const { language } = useParams();


  useEffect(() => {
    if (language) {
      setTarget((prevTarget) => ({
        ...prevTarget,
        language: language,
      }));
    }
    if (isDuplicate) {
      setTarget((prevTarget) => ({
        ...prevTarget,
        isLanguageSet: false,
      }));
    }
  }, []);

  const onCheckChanged = () => {
    setTarget((prevTarget) => ({
      ...prevTarget,
      isLanguageSet: !isLanguageSet,
    }));
  }

  return (
    <>
      {!isDuplicate &&
       <Card>
         <Checkbox
           checked={isLanguageSet}
           onChange={onCheckChanged}
         >
           <Text>{`Target Language: ${language}`}</Text>
         </Checkbox>
       </Card>}
    </>

  )
}
