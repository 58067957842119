/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { Dropdown, DropdownListItemProps } from "@ftbpro/mm-admin-ui-components";
import { css } from "@emotion/react";
import { Card } from "../../../Card/Card";
import { getTemplates } from "./campaignsTemplate.selector";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { TemplateState, templateState } from "./campaignsTemplate.atom";
import { CreateNewTemplate } from "./components/CreateNewTemplate/CreateNewTemplate";
import { SelectedTemplateActions } from "./components/CreateNewTemplate/SelectedTemplateActions/SelectedTemplateActions";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { campaignMessageState } from "../../../../../CreateCampaignMessage/createCampaignMessage.atom";
import { searchState } from "../SearchCampaignContainer/searchCampaign.atom";
import { CampaignType, SearchProvider, Template } from "../../../../../../createCampign.typs";
import { targetState } from "../../targetState/target.atom";


const styles = {
  card: {
    gap: 0,
  },
  buttons: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    gap: "24px",
  }),
  input: {
    marginTop: "24px"
  },
  snackBar: css({
    position: 'absolute',
    top: '-200px',
    right: '0px',
    width: '100%',
    height: '100%',
    zIndex: 1000,
  }),
}

export const CampaignsTemplate = () => {
  const templates = useRecoilValueLoadable(getTemplates);
  const [template, setTemplate] = useRecoilState(templateState);
  const setError = useSetRecoilState(campaignMessageState);
  const [templateItems, setTemplateItems] = useState([]);
  const { isSearchCampaign } = useRecoilValue(searchState);
  const { searchCampaign } = useRecoilValue(targetState);

  useEffect(() => {
    if (templates.state === 'hasError') {
      setError({ open: true, message: 'Error fetching templates' });
    }
  }, [templates.state])


  useEffect(() => {
    const getTemplateItems = () => {
      if (templates.state === 'hasValue' && Array.isArray(templates.contents?.templates)) {
        let templateList = templates.contents.templates;
        if (isSearchCampaign) {
          templateList = getSearchTemplateItems(templateList);
          if(template.selectedTemplate){
            setTemplate({} as TemplateState)
          }
        } else {
          templateList = templateList.filter((template: Template) => template.type !== CampaignType.Search)
        }
        const templateItemsFormatter = templateList.map((template: Template) => ({ value: template.name, id: template.id, provider: template.provider, type: template.type }));
        setTemplateItems(templateItemsFormatter);
      }
    }
    getTemplateItems();
  }, [templates.state, isSearchCampaign, searchCampaign?.searchProvider])

  const getSearchTemplateItems = (templateItems: Template[]) => {
    const searchProvider = searchCampaign?.searchProvider
    if (searchProvider) {
      return templateItems.filter(template => template.provider === searchProvider);
    }
    return templateItems.filter(template => template.type === CampaignType.Search)
  }


  const onSelectedTemplateChange = (e: ClickEventType, selectedItem: DropdownListItemProps) => {
    const { value, id, provider, type } = selectedItem as { value: string, id: string, provider: SearchProvider, type: CampaignType };
    setTemplate((prevTemplate) => {
      return {
        ...prevTemplate,
        selectedTemplate: { id, name: value, provider, type },
      }
    });
  }

  return (
    <Card style={styles.card}>
      <Dropdown
        placeholder={'Select'}
        items={templateItems}
        labelProps={{
          text: 'Choose Template',
        }}
        search
        loading={templates.state === 'loading'}
        selectedValue={template.selectedTemplate?.name || ''}
        onSelectedChange={onSelectedTemplateChange}
      />
      <SelectedTemplateActions />
      <CreateNewTemplate
      />
    </Card>
  );
}
