/** @jsxImportSource @emotion/react */
import { Checkbox, Dropdown, DropdownListItemProps, Text } from '@ftbpro/mm-admin-ui-components';
import { Card } from '../../../Card/Card';
import { ClickEventType } from '@ftbpro/mm-admin-ui-components/src/types';
import { useRecoilState, useRecoilValue } from 'recoil';
import { duplicateData, selectedDuplicateCampaigns, useOriginalPostIDState } from './duplicate.state';
import { useEffect } from 'react';

export const DuplicateCampaigns = () => {
  const [selectedCampaigns, setSelectedCampaigns] = useRecoilState(selectedDuplicateCampaigns);
  const [useOriginalPostID, setUseOriginalPostID] = useRecoilState(useOriginalPostIDState);
  const { isSearch } = useRecoilValue(duplicateData);
  const duplicateCampaigns = useRecoilValue(duplicateData).duplicateCampaigns as string[];

  useEffect(() => {
    setSelectedCampaigns(duplicateCampaigns);
  }, [duplicateCampaigns, setSelectedCampaigns]);

  const onSelectedChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setSelectedCampaigns((prevState) => {
      const selectedCampaigns = prevState ? [...prevState] : [];
      return [...selectedCampaigns, selectedItem.value as string];
    });
  };

  const onCampaignRemove = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setSelectedCampaigns((prevState) => prevState.filter((campaign) => campaign !== selectedItem.value));
  };

  const onCampaignsSelectAll = (e: ClickEventType<HTMLElement>, selectedItems: DropdownListItemProps[]) => {
    setSelectedCampaigns(() => selectedItems.map((item) => item.value as string));
  };

  const onUseOriginalPostIDChange = () => {
    setUseOriginalPostID(!useOriginalPostID);
  };

  return (
    <>
      <Card>
        <Dropdown
          items={duplicateCampaigns}
          showCheckboxes
          multiSelection
          search
          onSelectedChange={onSelectedChange}
          onValueRemove={onCampaignRemove}
          onSelectAllChange={onCampaignsSelectAll}
          selectedValues={selectedCampaigns}
          selectAllLabel={'Select All'}
          placeholder={'Select Campaigns'}
        />
      </Card>
      {!isSearch && <Card>
        <Checkbox
          checked={useOriginalPostID}
          onChange={onUseOriginalPostIDChange}
        >
          <Text type={Text.TEXT_TYPES.PARAGRAPH_M}>Use Existing Post ID</Text>
        </Checkbox>
      </Card>}
    </>
  );
};
