import { Dropdown, DropdownListItemProps, FontWeight, Text } from "@ftbpro/mm-admin-ui-components"
import { Card } from "../../../Card/Card"
import { useEffect, useState } from "react"
import { CustomDate } from "./CustomDate/CustomDate"
import { useRecoilState } from 'recoil';
import { Scheduled } from '../../../../../../createCampign.typs';
import { settingsState } from '../../settingsState/settings.atom';


const scheduledItems = [
  { content: 'Upload Now', value: Scheduled.UploadNow },
  { content: 'Tomorrow 00 EST', value: Scheduled.Tomorrow00EST },
  { content: 'Custom', value: Scheduled.Custom },
]

export const ScheduledCampaign = () => {
  const [settings, setSettings] = useRecoilState(settingsState);
  const { scheduledCampaign, isPausedCampaign } = settings;
  const [scheduledList, setScheduledList] = useState(scheduledItems);

  useEffect(() => {
    if (isPausedCampaign) {
      setScheduledList([{ content: 'Upload Now', value: Scheduled.UploadNow }]);
      if (scheduledCampaign !== Scheduled.UploadNow) {
        onSelectedScheduledChange(Scheduled.UploadNow);
      }
    }else{
      setScheduledList(scheduledItems);
    }
  }, [isPausedCampaign])

  const onSelectedScheduledChange = (scheduledItem: Scheduled) => {
    setSettings((prevSettings) => {
      if (scheduledItem !== Scheduled.Custom) {
        return {
          ...prevSettings,
          scheduledCampaign: scheduledItem,
          customDate: undefined
        }
      }
      return {
        ...prevSettings,
        scheduledCampaign: scheduledItem
      }
    });
  }

  return (
    <Card>
      <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium}>Scheduled</Text>
      <Dropdown items={scheduledList}
        selectedValue={scheduledCampaign}
        onSelectedChange={(e, item: DropdownListItemProps) => onSelectedScheduledChange(item.value as Scheduled)}
      />
      {
        scheduledCampaign === Scheduled.Custom &&
        <CustomDate
        />
      }
    </Card>
  )
}
