import { Dropdown, DropdownListItemProps } from "@ftbpro/mm-admin-ui-components"
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { targetState } from "../../../../targetState/target.atom";
import { Tonic } from "../../../../../../../../createCampign.typs";
import { useEffect, useState } from "react";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { tonicCampaigns } from "./tonicCampaign.selector";



export const TonicCampaign = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const { searchCampaign } = target;
  const tonicCampaignsLoadable = useRecoilValueLoadable(tonicCampaigns);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [tonicCampaignsList, setTonicCampaignsList] = useState<any[]>([]);

  useEffect(() => {
    switch (tonicCampaignsLoadable.state) {
      case 'loading':
        setLoading(true);
        setError('');
        break;
      case 'hasError':
        setLoading(false);
        setError(tonicCampaignsLoadable.contents.message || 'An error occurred');
        break;
      case 'hasValue':
        setLoading(false);
        setError('');
        setTonicCampaignsList(tonicCampaignsLoadable.contents);
        break;
      default:
        break;
    }
  }, [tonicCampaignsLoadable.state, tonicCampaignsLoadable.contents]);

  const onSelectedChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    const { value, id } = selectedItem as { id: string, value: string }
    setTarget((prevTarget) => ({
      ...prevTarget,
      searchCampaign: {
        ...searchCampaign,
        tonicCampaign: { id, name: value },
      } as Tonic
    }));
  }

  return (
    <Dropdown
      placeholder={'Tonic Campaign'}
      items={tonicCampaignsList}
      loading={loading}
      onSelectedChange={onSelectedChange}
      search
      selectedValue={(searchCampaign as Tonic)?.tonicCampaign?.name}
      error={error}
    />
  )
}
