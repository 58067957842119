import React, { useState } from 'react';
import { User } from './../../core/types/users.types';
import { Outlet, useOutletContext } from 'react-router-dom';


type ConfigurationsProps = {
  users: User[];
}

type ConfigurationsContext = {
  configurationData: ConfigurationsProps;
  setConfigurationData: (data: ConfigurationsProps) => void;
}

const initialState = {
  users: [],
};


export const ConfigurationsProvider = () => {
  const [configurationData, setConfigurationData] = useState<ConfigurationsProps>(initialState);

  return (
    <Outlet context={{ configurationData, setConfigurationData }}/>
  );
};

export const useConfigurationData = () => useOutletContext<ConfigurationsContext>();
