import { useRecoilValue } from 'recoil';
import { ScheduledCampaign } from './components/ScheduledCampaign/ScheduledCampaign';
import { AccountsDomains } from './components/AccountsDomains/AccountsDomains';
import { PausedCampaign } from './components/PausedCampaign/PausedCampaign';
import { DuplicateCampaigns } from './components/DuplicateCampaigns/DuplicateCampaigns';
import { isDuplicateState } from './components/DuplicateCampaigns/duplicate.state';


export const Settings = () => {
  const isDuplicate = useRecoilValue(isDuplicateState);

  return (
    <>
      <PausedCampaign/>
      <ScheduledCampaign/>
      {isDuplicate
        ? <DuplicateCampaigns/>
        : <AccountsDomains/>
      }
    </>
  )
}
