/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DotsIcon } from '@ftbpro/mm-admin-assets';
import { Icon, Input, Text } from '@ftbpro/mm-admin-ui-components';
import { SyntheticEvent, useState } from 'react';
import { useRecoilState } from 'recoil';
import { settingsState } from '../../../settingsState/settings.atom';

const styles = {
  container: css({
    display: 'flex',
    flexDirecion: 'row',
    gap: '4px',
    alignItems: 'center',
  }),
  standartTime: css({
    marginLeft: '8px',
  })
}

const variables = {
  input: {
    width: '40px',
    height: '40px',
  }
}

interface TimeInputProps {
  standartTime?: string;
}
export const TimeInput = (props: TimeInputProps) => {
  const { standartTime } = props;
  const [settings, setSettings] = useRecoilState(settingsState);
  const [hours, setHours] = useState<string>(settings.customDate?.hour || '00');
  const [minutes, setMinutes] = useState<string>(settings.customDate?.minutes || '00');

  const getNumberFormatter = (number: string) => {
    if (number.length === 1) {
      return `0${number}`;
    }
    return number;
  }

  const onMinutesChange = (e: SyntheticEvent<HTMLElement, Event>, data?: string) => {
    if ( data && !data.includes(' ') && Number(data) <= 59 && Number(data) >= 0) {
      setMinutes(data?.slice(0, 2) as string);
    }
  }

  const onHoursChange = (e: SyntheticEvent<HTMLElement, Event>, data?: string) => {
    if (data && !data.includes(' ')  && Number(data) <= 23 && Number(data) >= 0) {
      setHours(data?.slice(0, 2) as string);
    }
  }

  const onMinutesBlur = () => {
    const minutesFormatter = getNumberFormatter(minutes);
    setMinutes(getNumberFormatter(minutesFormatter));
    setSettings((prevSettings) => {
      const copyCustomDate = prevSettings.customDate ? { ...prevSettings.customDate } : { date: null, hour: '00', minutes: '00' };
      return {
        ...prevSettings,
        customDate: {
          ...copyCustomDate,
          minutes: minutesFormatter,
        }
      }
    });

  }

  const onHoursBlur = () => {
    const hoursFormatter = getNumberFormatter(hours);
    setHours(getNumberFormatter(hoursFormatter));
    setSettings((prevSettings) => {
      const copyCustomDate = prevSettings.customDate ? { ...prevSettings.customDate } : { date: null, hour: '00', minutes: '00' };
      return {
        ...prevSettings,
        customDate: {
          ...copyCustomDate,
          hour: hoursFormatter,
        }
      }
    });
  }

  return (
    <div css={styles.container}>
      <Input
        variables={variables.input}
        placeholder="00"
        value={hours}
        onChange={onHoursChange}
        onBlur={onHoursBlur}
      />
      <Icon icon={DotsIcon} width={12} height={12} />
      <Input
        variables={variables.input}
        placeholder="00"
        value={minutes}
        onChange={onMinutesChange}
        onBlur={onMinutesBlur}
      />
      <Text color={"#7F7F7F"} style={styles.standartTime}>{standartTime}</Text>
    </div>
  );
}
