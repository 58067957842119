/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Loader, SNACK_BAR_STATUSES, TextButton, Text } from "@ftbpro/mm-admin-ui-components"
import { useState } from "react";
import { getUserDetails } from "../../../../../../../../../../../../../../core/utils/user.utils";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import {  templatesRefreshTrigger, templateState } from "../../../campaignsTemplate.atom";
import { CampaignType, SearchCampaign, Template } from "../../../../../../../../../createCampign.typs";
import { getTemplates } from "../../../campaignsTemplate.selector";
import { CampaignsServiceProvider } from "../../../../../../../../../../../../../../services/campaigns/CampaignsServiceProvider";
import { campaignsDraftTriggeredState, campaignsTableState } from "../../../../../../../../../createCampaignState/createCampaign.selector";
import { formatCampaignsDraftsForClient } from "../../../../../../../settingsAndTarget.formatter";
import { ShareUsers } from "./components/ShareUsers/ShareUsers";
import { campaignMessageState } from "../../../../../../../../CreateCampaignMessage/createCampaignMessage.atom";
import { targetState } from "../../../../../targetState/target.atom";

const styles = {
  buttons: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    gap: '24px',
    marginTop: '4px',
  }),
  loader: css({
    display: 'flex',
    justifyContent: 'right',
    marginTop: '10px'
  }),
  snackBar: css({
    position: 'absolute',
    top: '-55px',
    right: '0px',
    width: '100%',
  }),
};

export const SelectedTemplateActions = () => {
  const [template, setTemplate] = useRecoilState(templateState);
  const selectedTemplate = template.selectedTemplate;
  const campaignListTemplateId = template.tableCampaignsTemplateId;
  const templatesList = useRecoilValueLoadable(getTemplates)?.contents?.templates as Template[];
  const setCampaignsDraftTriggerState = useSetRecoilState(campaignsDraftTriggeredState);
  const [campaignsTable, setCampaignsTable] = useRecoilState(campaignsTableState);
  const [shareUsers, setShareUsers] = useState(false);
  const [loading, setLoading] = useState(false);
  const setError = useSetRecoilState(campaignMessageState);
  const setRefreshTemplatesTrigger = useSetRecoilState(templatesRefreshTrigger);
  const [target, setTarget] = useRecoilState(targetState);
  const templateOwnerId = templatesList?.find((template) => template.id === selectedTemplate?.id)?.ownerID;
  const setMessage = useSetRecoilState(campaignMessageState);

  const isTemplateOwner = () => {

    if (!selectedTemplate || !templatesList) {
      return true;
    }
    const userId = getUserDetails().id;
    return templateOwnerId === userId;
  }

  const onRunTemplate = async () => {
    try {
      setCampaignsDraftTriggerState({ triggered: true, loading: true });
      const templateDraft = await CampaignsServiceProvider.getTemplateById(selectedTemplate?.id as string);
      const formattedTemplateDrafts = formatCampaignsDraftsForClient(templateDraft.template.drafts, campaignsTable.campaignsList);
      setCampaignsTable(
        (prevCampaignsTable) => {
          return {
            ...prevCampaignsTable,
            campaignsList: formattedTemplateDrafts
          }
        }
      );
      setCampaignsDraftTriggerState({ triggered: true, loading: false });
      setTemplate((prevTemplate) => {
        return {
          ...prevTemplate,
          tableCampaignsTemplateId: selectedTemplate?.id as string,
        }
      });
      if (!target.searchCampaign?.searchProvider) {
        setTarget(prevState => {
          return {
            ...prevState,
            searchCampaign: {
              searchProvider: selectedTemplate?.provider
            } as SearchCampaign
          }
        })
      }
    } catch {
      const errorMessage = 'Error generating template drafts';
      setMessage({ open: true, status: SNACK_BAR_STATUSES.ERROR, message: errorMessage });
      setCampaignsDraftTriggerState({ triggered: true, loading: false });
      setCampaignsTable(
        (prevCampaignsTable) => {
          return {
            ...prevCampaignsTable,
            campaignsList: []
          }
        }
      );
    }
  }

  const onEditTemplate = async () => {
    const updatedTemplate = {
      name: selectedTemplate?.name as string,
      platform: 'facebook',
      type: selectedTemplate?.type as CampaignType,
      provider: selectedTemplate?.provider,
      template: {
        drafts: campaignsTable.campaignsList
      }
    }
    try {
      setLoading(true);
      await CampaignsServiceProvider.updateTemplate(selectedTemplate?.id as string, updatedTemplate)
      setLoading(false);
    } catch {
      setLoading(false);
      setError({ open: true, message: 'Failad to update template' });
    }
  }

  const onDeleteTemplate = async () => {
    try {
      setLoading(true);
      await CampaignsServiceProvider.deleteTemplate(selectedTemplate?.id as string);
      setLoading(false);
      setRefreshTemplatesTrigger((prev) => prev + 1);
      setTemplate((prevTemplate) => {
        return {
          ...prevTemplate,
          selectedTemplate: null,
        }
      });
    } catch {
      setLoading(false);
      setError({ open: true, message: 'Failad to delete template', status: SNACK_BAR_STATUSES.ERROR });
    }
  }

  return (
    <>
      {
        loading ?
          <div css={styles.loader}>
            <Loader />
          </div>
          :
          <div css={styles.buttons}>
            <TextButton content={'Share'} disabled={!selectedTemplate} onClick={() => setShareUsers(true)} textType={Text.TEXT_TYPES.PARAGRAPH_L} />
            {
              isTemplateOwner() &&
              <>
                <TextButton content={'Delete'} disabled={!selectedTemplate} onClick={onDeleteTemplate} textType={Text.TEXT_TYPES.PARAGRAPH_L} />
                <TextButton content={'Save'} disabled={!selectedTemplate || (selectedTemplate.id !== campaignListTemplateId)} onClick={onEditTemplate} textType={Text.TEXT_TYPES.PARAGRAPH_L} />
              </>
            }
            <TextButton content={'Run'} disabled={!selectedTemplate} onClick={onRunTemplate} textType={Text.TEXT_TYPES.PARAGRAPH_L} />
          </div>
      }
      {
        shareUsers &&
        <ShareUsers closeShare={() => setShareUsers(false)} templateOwnerId={templateOwnerId} />
      }
    </>
  )
}
