const MAX_DAILY_BUDGET = 300;
const isPositiveNonZeroInteger = (text: string) => {
  const n = Math.floor(Number(text));
  return n !== Infinity && String(n) === text && n > 0;
};

export const validateDefaultDailyBudget = (value: string) => {
  if (!value || value === '' || !isPositiveNonZeroInteger(value) || Number(value) > MAX_DAILY_BUDGET) {
    return `Default Daily Budget should be a positive integer (Min of 1, Max of ${MAX_DAILY_BUDGET})`;
  }
  return '';
};

export const validateDefaultBid = (value: string, maxBid: string) => {
  if (!value || value === '' || Number(value) < 0 || Number(value) > Number(maxBid)) {
    return 'Default Bid should be positive and lower than Max Bid';
  }
  return '';
};

export const validateMaxBid = (value: string) => {
  if (!value || value === '' || Number(value) < 0) {
    return 'Max Bid should be a positive number';
  }
  return '';
};
