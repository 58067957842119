import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CreativeInsights } from '../../pages/creativeInsights/CreativeInsights';
import { GroupsProvider } from '../../pages/groups/groupsContext';
import { GroupsTablePage } from '../../pages/groups/groupsTablePage/GroupsTablePage';
import { GroupsAdjustPage } from '../../pages/groups/groupAdjustPage/GroupAdjustPage';
import { ConfigurationsProvider } from '../../pages/configurations/configurationsContext';
import { ConfigurationsTablePage } from '../../pages/configurations/configurationsTablePage/ConfigurationsTablePage';
import { ConfigurationAdjustPage } from '../../pages/configurations/configurationAdjustPage/ConfigurationAdjustPage';
import { CreateCampaignDashboard } from '../../pages/createCampaign/CreateCampaignDashboard/compnents/CreateCampaignDashboard/CreateCampaignDashboard';

export const AppRoutes = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/create-campaigns/:group_name/:article_name/:language" element={<CreateCampaignDashboard/>} />
          <Route path="/create-campaigns/duplicate" element={<CreateCampaignDashboard/>} />
          <Route path="/creative-insights" element={<CreativeInsights />} />
          <Route element={<GroupsProvider />}>
            <Route path="groups" element={<GroupsTablePage />} />
            <Route path="groups/new" element={<GroupsAdjustPage />} />
            <Route path="groups/:id" element={<GroupsAdjustPage />} />
          </Route>
          <Route element={<ConfigurationsProvider />}>
            <Route path="campaign-settings" element={<ConfigurationsTablePage />} />
            <Route path="campaign-settings/new" element={<ConfigurationAdjustPage />} />
            <Route path="campaign-settings/:id" element={<ConfigurationAdjustPage />} />
          </Route>
        </Routes>
      </Router>
    </>
  )
}
