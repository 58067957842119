import { User } from '../../../../../core/types/users.types';
import React, { Dispatch, SetStateAction } from 'react';
import { Summary } from '@ftbpro/mm-admin-ui-components';

interface SelectionSummaryProps {
  selectedUsers: User[],
  setSelectedUsers: Dispatch<SetStateAction<User[]>>,
}

export const SelectionSummary = ({
                                   selectedUsers,
                                   setSelectedUsers,
                                 }: SelectionSummaryProps) => {

  const summerySectionsData = [
    {
      sectionItems: selectedUsers.map(user => {
        return {
          id: user.id,
          name: user.email,
        };
      }),
      sectionTitle: 'Users'
    },
  ];

  return (
    <div>
      <Summary
        onClearAll={() => {
          setSelectedUsers([]);
        }}
        onRemoveItem={(summeryItem, itemType) => {
          if (itemType === 'Users') {
            setSelectedUsers(val => {
              const updatedSelectedUsers = val.filter(userItem => userItem.id !== summeryItem.id);
              return updatedSelectedUsers;
            });
          }
        }}
        sectionsData={summerySectionsData}
        title="Summary"
        variables={{
          width: '100%',
        }}
        style={{ height: '655px', overflow: 'scroll' }}
      />
    </div>
  );
};
