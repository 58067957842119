/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Toggle, Text } from "@ftbpro/mm-admin-ui-components";
import { useState } from "react";
import { AudiencesWebsite } from "./components/AudiencesWebsite/AudiencesWebsite";
import { AudiencesLookALike } from "./components/ AudiencesLookALike/ AudiencesLookALike";
import { CustomPixels } from "./components/CustomPixels/CustomPixels";
import { useRecoilState, useRecoilValue } from "recoil";
import { targetState } from "../../../../targetState/target.atom";
import { searchState } from "../../../SearchCampaignContainer/searchCampaign.atom";


const styles = {
  toggleWrapper: css({
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  })
}

export const CustomSetup = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const [showCustomAudiences, setShowCustomAudiences] = useState(target.customAudiences ? true : false);
  const [showCustomPixels, setShowCustomPixels] = useState(target.customPixel ? true : false);
  const { isSearchCampaign } = useRecoilValue(searchState);

  const onToggleCustomAudiencesChange = () => {
    if (!showCustomAudiences) {
      setTarget((prevTarget) => ({
        ...prevTarget,
        customAudiences: undefined,
      }));
    }
    setShowCustomAudiences(!showCustomAudiences)
  }

  const onToggleCustomPixelsChange = () => {
    if (!showCustomPixels) {
      setTarget((prevTarget) => ({
        ...prevTarget,
        customPixel: undefined,
      }));
    }
    setShowCustomPixels(!showCustomPixels)
  }

  return (
    <>
      <div css={styles.toggleWrapper}>
        <Toggle
          checked={showCustomAudiences}
          onChange={onToggleCustomAudiencesChange}
        />
        <Text
          type={Text.TEXT_TYPES.PARAGRAPH_M}
        >
          Target By Custom Audiences
        </Text>
      </div>

      {
        showCustomAudiences && (
          <>
            <AudiencesWebsite />
            <AudiencesLookALike />
          </>
        )
      }


      {
        !isSearchCampaign && (
          <div css={styles.toggleWrapper}>
            <Toggle
              checked={showCustomPixels}
              onChange={onToggleCustomPixelsChange}
            />
            <Text
              type={Text.TEXT_TYPES.PARAGRAPH_M}
            >
              Custom Pixels
            </Text>
          </div>
        )}

      {
        showCustomPixels && (
          <CustomPixels />
        )
      }
    </>
  );
}



