import { Dropdown, DropdownListItemProps } from "@ftbpro/mm-admin-ui-components"
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { targetState } from "../../../../targetState/target.atom";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { campaignData } from "../../../../targetState/target.selector";
import { useEffect } from "react";
import { Objective } from "../../../../../../../../createCampign.typs";
import { searchState } from "../../../SearchCampaignContainer/searchCampaign.atom";

export const ConversionsEvent = () => {

  const [target, setTarget] = useRecoilState(targetState);
  const { objective, conversionsEvent } = target;
  const data = useRecoilValueLoadable(campaignData);
  const { isSearchCampaign } = useRecoilValue(searchState);

  useEffect(() => {
    if (objective === Objective.Leads) {
      setTarget((prevTarget) => {
        return {
          ...prevTarget,
          conversionsEvent: ['LEAD']
        }
      });
    } else if (objective === Objective.Sales && isSearchCampaign) {
      setTarget((prevTarget) => {
        return {
          ...prevTarget,
          conversionsEvent: ['PURCHASE']
        }
      });
    }
  }, [isSearchCampaign, objective]);

  const getConversionsEvent = () => {
    if (data.state === 'hasValue') {
      return data.contents.audience.facebook.allConversionEvents;
    }
    return [];
  }

  const onSelectedChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setTarget((prevTarget) => {
      const copyConversionsEvent = prevTarget.conversionsEvent ? [...prevTarget.conversionsEvent] : [];
      return {
        ...prevTarget,
        conversionsEvent: [...copyConversionsEvent, selectedItem.value as string]
      }
    });
  }

  const onConversionsEventDelete = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        conversionsEvent: prevTarget.conversionsEvent.filter((conversionsEvent) => conversionsEvent !== selectedItem.value)
      }
    });
  }

  const onConversionsEventSelectAll = (e: ClickEventType<HTMLElement>, selectedItems: DropdownListItemProps[]) => {
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        conversionsEvent: selectedItems.map((item) => item.value as string)
      }
    });
  }

  return (
    objective === Objective.Sales && !isSearchCampaign ?
      <Dropdown
        items={getConversionsEvent()}
        placeholder="Conversions Event"
        selectAllLabel="Select All"
        multiSelection
        showCheckboxes
        expandableChips
        search
        onSelectedChange={onSelectedChange}
        loading={data.state === 'loading'}
        onValueRemove={onConversionsEventDelete}
        onSelectAllChange={onConversionsEventSelectAll}
        selectedValues={conversionsEvent}
      />
      : null
  )
}
