/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, DropdownListItemProps, Filter, Loader, Text } from '@ftbpro/mm-admin-ui-components';
import { capitalizeFirstLetter } from '@ftbpro/mm-admin-core-utils';
import { User } from '../../../core/types/users.types';
import { UsersServiceConfigsProvider } from '../../../services/users/UsersServiceConfigsProvider';
import { formatConfigurations } from '../../../services/configurations/ConfigurationsServiceFormatter';
import { ClickEventType } from '@ftbpro/mm-admin-ui-components/src/types';
import { useNavigateToPage, useOnArrowBackClick } from '../../../hooks/useNavigate';
import { useConfigurationData } from '../configurationsContext';
import { getUserDetails } from '../../../core/utils/user.utils';
import { Configuration } from '../configurations.types';
import { ConfigurationsServiceConfigsProvider } from '../../../services/configurations/ConfigurationsServiceConfigsProvider';
import { ConfigurationsTableContent } from './components/configurationsTableContent/ConfigurationsTableContent';

import { css } from '@emotion/react';

const styles = {
  container: css({
    height: '97vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px',
    background: '#F8F7FA',
    minHeight: 0,
  }),
  flexSpacesBetweenRow: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  }),
  titleAndFilterContainer: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '40px',
    alignItems: 'center',
  }),
  filter: css({
    backgroundColor: '#E8E7F0',
    borderRadius: '4px',
  })
};

const variables = {
  filter: {
    buttonTextColor: '#1F0F66',
    toggleIndicatorColor: '#BCB7D2',
    textPadding: '6px 8px 6px 16px',
  }
};

export const ConfigurationsTablePage = () => {
  const [isLoadingConfigurations, setIsLoadingConfigurations] = useState(true);
  const [allConfigurations, setAllConfigurations] = useState<Configuration[]>([]);
  const [configurationsTableData, setConfigurationsTableData] = useState<Configuration[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const { configurationData, setConfigurationData } = useConfigurationData();
  const { users } = configurationData;
  const userNames = useMemo(() => [{ value: 'All Users', id: 'all' }, ...users.map(user => ({ value: `${capitalizeFirstLetter(user.name)} (${user.email})`, id: user.id }))], [users]);

  const navigate = useNavigateToPage();

  useOnArrowBackClick('campaign-settings');

  useEffect(() => {
    getAllConfigurations()
      .catch(() => {
        setIsLoadingConfigurations(false);
      });
  }, []);

  useEffect(() => {
    if (!users.length) {
      getUsersList();
    }
  }, []);

  const getAllConfigurations = useCallback(async () => {
    const configurationsResponse = await ConfigurationsServiceConfigsProvider.getConfigurations();
    const formattedConfigurations = formatConfigurations(configurationsResponse.configs);
    const userDetails = getUserDetails();

    if (userDetails.permissions.includes('campaign_settings_management')) {
      setAllConfigurations(formattedConfigurations);
      setConfigurationsTableData(formattedConfigurations);
    } else {
      const filteredConfigurations = formattedConfigurations.filter(config => config.owner === userDetails.email);
      setAllConfigurations(filteredConfigurations);
      setConfigurationsTableData(filteredConfigurations);
    }
    setIsLoadingConfigurations(false);
  }, []);


  const getUsersList = useCallback(async () => {
    setIsLoadingUsers(true);
    const usersResponse = await UsersServiceConfigsProvider.getUsersList();
    setIsLoadingUsers(false);
    setConfigurationData({ ...configurationData, users: usersResponse.users });
  }, []);

  const filterConfigurationsByUser = (selectedUser: User | null) => {
    if (!selectedUser) {
      return allConfigurations;
    }
    return allConfigurations.filter(configuration => configuration.users?.includes(selectedUser.id));
  };


  const onSelectedUserChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    const { id } = selectedItem as { id: string, value: string };
    const selectedUser = users.find(user => user.id === id) as User;
    const filteredConfigurations = filterConfigurationsByUser(selectedUser);
    setConfigurationsTableData(filteredConfigurations);
  };

  const onCreateClick = () => {
    navigate('campaign-settings/new');
  };

  return (
    <div css={styles.container}>
      <div css={styles.flexSpacesBetweenRow}>
        <div css={styles.titleAndFilterContainer}>
          <Text type={Text.TEXT_TYPES.PARAGRAPH_XXXL}>Campaign Settings</Text>
          <Filter
            loadingItems={isLoadingUsers}
            items={userNames}
            selectedValue={'All Users'}
            onSelectedChange={onSelectedUserChange}
            style={styles.filter}
            variables={variables.filter}
          />
        </div>
        <Button content={'Create'}
                onClick={() => {
                  onCreateClick();
                }}
        />
      </div>
      {isLoadingConfigurations
        ? <Loader size={Loader.LOADER_SIZES.FULL_SCREEN}/>
        : <ConfigurationsTableContent configurationsTableData={configurationsTableData} setIsLoading={setIsLoadingConfigurations} getConfigurationsData={getAllConfigurations}/>}
    </div>
  );
};


