import { HTTP_METHODS, mmNetworkService } from '../../core/network/mmServicesApiProvider';
import { User } from '../../core/types/users.types';
import { Group } from '../../pages/groups/groups.types';


const PM_USER_SERVICE_BASE_URL = process.env.REACT_APP_PM_USER_SERVICE_BASE_URL;

const GROUPS = 'groups';
const GROUP_ITEMS = 'group-items';
const USERS = 'users';
const AUTH = 'auth';

interface Config {
    name: string;
    type: string;
    platform: string;
    items: any[];
    activity?: string;
}

export type GroupsResponse = {
    groups: [];
}

export type UsersListResponse = {
    users: User[];
}

export type GroupsDeleteResponse = {
    data: string | null;
}
export type GroupsItemsResponse = {
    items: { item: string }[];
}
export type SelectedUsersListResponse = {
    users: User[];
}


export type GetGroupResponse = Group;

export class UsersServiceConfigsProvider {
  static groupsEndpoint() {
        return `${PM_USER_SERVICE_BASE_URL}/${GROUPS}`;
    }

  static groupEndpoint(groupId: string) {
    return `${PM_USER_SERVICE_BASE_URL}/${GROUPS}/${groupId}`;
  }

// GROUPS

    static getGroups(): Promise<GroupsResponse> {
      const url = UsersServiceConfigsProvider.groupsEndpoint();
        const configs = {
            method: HTTP_METHODS.GET,
        };

        return mmNetworkService.fetch(url, configs);
    }

    static deleteGroup(id: string): Promise<GroupsDeleteResponse> {
      const url = UsersServiceConfigsProvider.groupEndpoint(id);
        const configs = {
            method: HTTP_METHODS.DELETE,
        };

        return mmNetworkService.fetch(url, configs);
    }

//GROUP ITEMS
    static groupItemsEndpoint(groupType: string, platform: string) {
        return `${PM_USER_SERVICE_BASE_URL}/${GROUP_ITEMS}?type=${groupType}&platform=${platform}`;
    }

    static getGroupItems(groupType: string, platform: string): Promise<GroupsItemsResponse> {
      const url = UsersServiceConfigsProvider.groupItemsEndpoint(groupType, platform.toLowerCase());
        const configs = {
            method: HTTP_METHODS.GET,
        };

        return mmNetworkService.fetch(url, configs);
    }

// GROUP CREATE
    static createGroup(name: string, type: string, platform: string, items: string[], selectedActivity: string) {
      const url = UsersServiceConfigsProvider.groupsEndpoint();

        const config: Config = {
            name: name,
            type: type,
            platform: platform,
            items: items,
        }


        if (selectedActivity && selectedActivity !== '') {
            config.activity = selectedActivity;
        }

        const configs = {
            method: HTTP_METHODS.POST,
            body: JSON.stringify(config),
        };
        return mmNetworkService.fetch(url, configs);
    }

//USERS

  static getUserDetails() {
    const url =  `${PM_USER_SERVICE_BASE_URL}/${AUTH}`;

    const configs = {
        method: HTTP_METHODS.GET,
    };

    return mmNetworkService.fetch(url, configs);
   }

  static usersListEndpoint() {
        return `${PM_USER_SERVICE_BASE_URL}/${USERS}`;
    }

    static getUsersList(): Promise<UsersListResponse> {
      const url = UsersServiceConfigsProvider.usersListEndpoint();

        const configs = {
            method: HTTP_METHODS.GET,
        };

        return mmNetworkService.fetch(url, configs);
    }

    static selectedUsersListEndpoint(groupId: string) {
        return `${PM_USER_SERVICE_BASE_URL}/${GROUPS}/${groupId}/${USERS}`;
    }

    static getSelectedUsersList(groupId: string): Promise<SelectedUsersListResponse> {
      const url = UsersServiceConfigsProvider.selectedUsersListEndpoint(groupId);

        const configs = {
            method: HTTP_METHODS.GET,
        };

        return mmNetworkService.fetch(url, configs);
    }

    static adjustUsersList(groupId: string, usersIdList: string[]): Promise<UsersListResponse> {
      const url = UsersServiceConfigsProvider.selectedUsersListEndpoint(groupId);
        const config = {
            userIds: usersIdList
        }
        // POST /groups/:id/users
        const configs = {
            method: HTTP_METHODS.POST,
            body: JSON.stringify(config),
        };

        return mmNetworkService.fetch(url, configs);
    }

    static getGroup(groupId: string): Promise<GetGroupResponse> {
      const url = UsersServiceConfigsProvider.groupEndpoint(groupId);
        const configs = {
            method: HTTP_METHODS.GET,
        };
        return mmNetworkService.fetch(url, configs);
    }

    static updateGroup(groupId: string, name: string, type: string, platform: string, items: string[], selectedActivity: string) {
      const url = UsersServiceConfigsProvider.groupEndpoint(groupId);

        const config: Config = {
            name: name,
            type: type,
            platform: platform,
            items: items,
            ...(selectedActivity && { activity: selectedActivity }),
        }
        const configs = {
            method: HTTP_METHODS.PUT,
            body: JSON.stringify(config),
        };
        return mmNetworkService.fetch(url, configs);
    }
}
