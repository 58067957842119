import { atom } from "recoil";
import { CampaignType, SearchProvider } from "../../../../../../createCampign.typs";

export interface TemplateState {
  selectedTemplate: { id: string, name: string, provider?: SearchProvider, type: CampaignType } | null;
  tableCampaignsTemplateId: string | null;
};

export const templateState = atom<TemplateState>({
  key: 'templateState',
  default: {} as TemplateState
});

export const templatesRefreshTrigger = atom({
  key: 'templatesRefreshTrigger',
  default: 0
});

export const templateUsersRefreshTrigger = atom({
  key: 'templateUsersRefreshTrigger',
  default: 0
});


