/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { groupTypedItem } from '../groups.types';
import { User } from '../../../core/types/users.types';
import { UsersServiceConfigsProvider } from '../../../services/users/UsersServiceConfigsProvider';
import {
    Button,
    Loader,
    PageHeader,
    SNACK_BAR_STATUSES,
    SnackBar,
} from '@ftbpro/mm-admin-ui-components';
import { GroupType } from '../groups.enums';
import { SelectGroupItem } from './components/selectGroupItem/SelectGroupItem';
import { SelectUsers } from '../../../components/selectUsers/SelectUsers';
import { SelectionSummary } from './components/summary/SelectionSummary';
import { useParams } from 'react-router-dom';
import { useNavigateToPage } from '../../../hooks/useNavigate';
import { useGroupsData } from '../groupsContext';
import { GeneralData } from './components/generalData/GeneralData';
import { css } from '@emotion/react';

const styles = {
    container: css({
        height: '600px',
        display: 'flex',
        flexDirection: 'column',
        padding: '16px 24px',
        background: '#F8F7FA',
        minHeight: 0,
    }),
    groupItemsAndUsersColumns: css({
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: '24px',
        backgroundColor: '#F8F7FA',
        height: '48vh',
    }),
    wrapper: css({
        display: 'grid',
        gridTemplateColumns: '66% 1fr',
        backgroundColor: '#F8F7FA',
        gap: '24px',
        paddingTop: '12px',
        height: '100%',
        minWidth: '540px',
    }),
    formRows: css({
        display: 'grid',
        gridTemplateRows: '133px 102px 409px',
    }),
    snackBar: css({
        position: 'absolute',
        top: '-55px',
        right: '0px',
        width: '100%',
    }),
};
export const GroupsAdjustPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isUsersLoading, setIsUsersLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedPlatform, setSelectedPlatform] = useState<string>('');
    const [selectedActivity, setSelectedActivity] = useState<string>('');
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [selectedGroupType, setSelectedGroupType] = useState<GroupType>(GroupType.Domain);
    const [selectedGroupTypedItemList, setSelectedGroupTypedItemList] = useState<groupTypedItem[]>([]);
    const [groupName, setGroupName] = useState<string>('');
    const { id: groupId } = useParams<{ id: string }>();
    const { groupData, setGroupData } = useGroupsData();
    const { users } = groupData;
    const navigate = useNavigateToPage();

    const isEditing = !!groupId;

    useEffect(() => {
        if (isEditing) {
            getGroup();
        }
        getUsersList();
    }, []);


    const publishGroup = async () => {
        setIsLoading(true);
        let groupsResponse = null;
        let errorOccurred = false;

        try {
            if (isEditing) {
                groupsResponse = await UsersServiceConfigsProvider.updateGroup(
                    groupId,
                    groupName,
                    selectedGroupType,
                    selectedPlatform,
                    selectedGroupTypedItemList.map(item => item.item),
                    selectedActivity
                );
            } else {
                groupsResponse = await UsersServiceConfigsProvider.createGroup(
                    groupName,
                    selectedGroupType,
                    selectedPlatform,
                    selectedGroupTypedItemList.map(item => item.item),
                    selectedActivity
                );
            }

            if ((groupsResponse?.id || groupId) && selectedUsers) {
                await UsersServiceConfigsProvider.adjustUsersList(
                    (groupId || groupsResponse?.id),
                    selectedUsers.map(user => user.id)
                );
            }
        } catch (error: any) {
            errorOccurred = true;
            if (error?.code === 409) {
                setErrorMessage(error?.messages || '');
            }
            setIsError(true);
        }

        setIsLoading(false);
        getUsers();
        if (!errorOccurred) {
            navigate('groups');
        }
    };


    const getUsers = async () => {
        const usersResponse = await UsersServiceConfigsProvider.getUsersList();
        setGroupData({ ...groupData, users: usersResponse.users });
    }

    const getSelectedUsers = async (selectedGroupId: string) => {
        const selectedUsersListResponse = await UsersServiceConfigsProvider.getSelectedUsersList(selectedGroupId);
        if (selectedUsersListResponse.users) {
            setSelectedUsers(selectedUsersListResponse.users);
        }
    }

    const getUsersAndSelectedUsers = async (selectedGroupId: string) => {
        const users = UsersServiceConfigsProvider.getUsersList();
        const selectedUsers = UsersServiceConfigsProvider.getSelectedUsersList(selectedGroupId);
        const results = await Promise.all([users, selectedUsers]);
        setGroupData({ ...groupData, users: results[0].users });
        setSelectedUsers(results[1].users || []);
    }

    const getUsersList = async () => {
        if (!users.length) {
            setIsUsersLoading(true);
            if (isEditing) {
                await getUsersAndSelectedUsers(groupId);
            } else {
                await getUsers();
            }
            setIsUsersLoading(false);
        } else {
            if (isEditing) {
                setIsUsersLoading(true);
                await getSelectedUsers(groupId);
                setIsUsersLoading(false);
            }
        }
    };

    const getGroup = async () => {
        if (groupId) {
            setIsLoading(true);
            const getGroupResponse = await UsersServiceConfigsProvider.getGroup(groupId);
            setGroupName(getGroupResponse.name);
            setSelectedGroupType(getGroupResponse.type as GroupType);
            setSelectedPlatform(getGroupResponse.platform);
            setSelectedActivity(getGroupResponse?.activity || '')
            if (getGroupResponse.items) {
                const items: groupTypedItem[] = getGroupResponse.items.map((itemName) => {
                    return { item: itemName }
                });
                setSelectedGroupTypedItemList(items);
            }
            setIsLoading(false);
        }
    }
    return (
        <>
            <PageHeader title={isEditing ? "Edit Group" : "New Group"} onBackClicked={() => navigate('groups')}>
                <Button onClick={publishGroup}
                    disabled={!groupName || !selectedGroupTypedItemList.length}>{isEditing ? "Save Changes" : "Create"}</Button>
            </PageHeader>
            <div css={styles.container}>
                {isError && <div css={styles.snackBar}>
                    <SnackBar
                        text={errorMessage || 'Oops, something went wrong'}
                        status={SNACK_BAR_STATUSES.ERROR}
                        stayOpen={true}
                        variables={{ animationDuration: '2s' }}
                        onClose={() => {
                            setIsError(false);
                            setErrorMessage('');
                        }}
                    />
                </div>}
                {isLoading
                    ? <Loader size={Loader.LOADER_SIZES.FULL_SCREEN} />
                  : <div css={styles.wrapper}>
                        <div css={styles.formRows}>
                            <GeneralData
                              groupName={groupName}
                              setGroupName={setGroupName}
                              selectedPlatform={selectedPlatform}
                              setSelectedPlatform={setSelectedPlatform}
                              setSelectedGroupTypedItemList={setSelectedGroupTypedItemList}
                              selectedGroupType={selectedGroupType}
                              setSelectedGroupType={setSelectedGroupType}
                              selectedActivity={selectedActivity}
                              setSelectedActivity={setSelectedActivity}
                              isEditing={isEditing}
                            />
                            <div css={styles.groupItemsAndUsersColumns}>
                                <SelectGroupItem
                                    selectedGroupType={selectedGroupType}
                                    selectedGroupTypedItemList={selectedGroupTypedItemList}
                                    setSelectedGroupTypedItemList={setSelectedGroupTypedItemList}
                                    selectedPlatform={selectedPlatform}
                                />
                                <SelectUsers
                                    selectedUsers={selectedUsers}
                                    usersList={users}
                                    setSelectedUsers={setSelectedUsers}
                                    isLoading={isUsersLoading}
                                />
                            </div>
                        </div>
                        <SelectionSummary
                            selectedUsers={selectedUsers}
                            setSelectedUsers={setSelectedUsers}
                            selectedGroupType={selectedGroupType}
                            selectedGroupTypedItemList={selectedGroupTypedItemList}
                            setSelectedGroupTypedItemList={setSelectedGroupTypedItemList}
                        />
                    </div>}
            </div>
        </>

    );
};
