/** @jsxImportSource @emotion/react */
import { Checkbox, FontWeight, Text } from '@ftbpro/mm-admin-ui-components';
import { Card } from '../../../Card/Card';
import { css } from '@emotion/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { targetState } from '../../targetState/target.atom';
import { campaignsDraftTriggeredState } from '../../../../../../createCampaignState/createCampaign.selector';
import { searchState } from '../SearchCampaignContainer/searchCampaign.atom';
import { isDuplicateState } from '../../../Settings/components/DuplicateCampaigns/duplicate.state';

const styles = {
  checkboxWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  })
}

export const MoreOptions = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const { isDynamicCreative, uploadToInstagram } = target;
  const { triggered: applyDraftTriggered } = useRecoilValue(campaignsDraftTriggeredState);
  const { isSearchCampaign } = useRecoilValue(searchState);
  const isDuplicate = useRecoilValue(isDuplicateState);


  const onDynamicCreativeChange = () => {
    setTarget((prevTarget) => ({
      ...prevTarget,
      isDynamicCreative: !isDynamicCreative
    }));
  }


  const onUploadToInstagramChange = () => {
    setTarget((prevTarget) => ({
      ...prevTarget,
      uploadToInstagram: !uploadToInstagram
    }));
  }

  return (
    <Card>
      <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium}>More Options</Text>

      <div css={styles.checkboxWrapper}>
        {!isDuplicate &&
         <Checkbox
          checked={isDynamicCreative}
          onChange={onDynamicCreativeChange}
          disabled={applyDraftTriggered}
        >
          <Text type={Text.TEXT_TYPES.PARAGRAPH_M} >Dynamic Creative</Text>
         </Checkbox>}

        {!isSearchCampaign &&
          <Checkbox
            checked={uploadToInstagram}
            onChange={onUploadToInstagramChange}
          >
            <Text type={Text.TEXT_TYPES.PARAGRAPH_M}>Upload to Instagram</Text>
          </Checkbox>
        }
      </div>
    </Card>
  );
}
