/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { User } from '../../../core/types/users.types';
import { UsersServiceConfigsProvider } from '../../../services/users/UsersServiceConfigsProvider';
import {
  Button,
  CssTooltip,
  Loader,
  PageHeader,
  SNACK_BAR_STATUSES,
  SnackBar,
  TOOLTIP_POSITIONS,
} from '@ftbpro/mm-admin-ui-components';
import { SelectUsers } from '../../../components/selectUsers/SelectUsers';
import { SelectionSummary } from './components/summary/SelectionSummary';
import { useParams } from 'react-router-dom';
import { useNavigateToPage } from '../../../hooks/useNavigate';
import { useConfigurationData } from '../configurationsContext';
import { CampaignSettings } from './components/campaignSettings/CampaignSettings';
import { ConfigurationsServiceConfigsProvider } from '../../../services/configurations/ConfigurationsServiceConfigsProvider';
import { css } from '@emotion/react';
import { GeneralData } from './components/generalData/GeneralData';
import { capitalizeFirstLetter } from '@ftbpro/mm-admin-core-utils';
import { capitalizeFirstLetterOfSentences } from '../../../core/utils/utils';
import { validateConfiguration } from './configurationAdjustPage.validations';
import { TooltipContent } from '../../../components/TooltipContent/TooltipContent';

const GENERIC_ERROR_MESSAGE = 'Oops, something went wrong';
const USERS_CONFLICT_MESSAGE = 'users already assigned';

const styles = {
  container: css({
    height: '600px',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px',
    background: '#F8F7FA',
    minHeight: 0,
  }),
  settingsAndUsers: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '24px',
    backgroundColor: '#F8F7FA',
    height: '48vh',
    marginTop: '24px',
  }),
  wrapper: css({
    display: 'grid',
    gridTemplateColumns: '66% 1fr',
    backgroundColor: '#F8F7FA',
    gap: '24px',
    paddingTop: '12px',
    height: '100%',
    minWidth: '540px',
  }),
  formRows: css({
    display: 'grid',
    gridTemplateRows: '133px 102px 409px',
  }),
  snackBar: css({
    position: 'absolute',
    top: '-55px',
    right: '0px',
    width: '100%',
  }),
  tooltip: {
    zIndex: 4,
  }
};

export const ConfigurationAdjustPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(GENERIC_ERROR_MESSAGE);
  const [configurationName, setConfigurationName] = useState<string>('');
  const [selectedPlatform, setSelectedPlatform] = useState<string>('');
  const [defaultDailyBudget, setDefaultDailyBudget] = useState<string>('');
  const [defaultBid, setDefaultBid] = useState<string>('');
  const [maxBid, setSMaxBid] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const { id: configurationId } = useParams<{ id: string }>();
  const { configurationData, setConfigurationData } = useConfigurationData();
  const { users } = configurationData;
  const [configurationErrors, setConfigurationErrors] = useState<string[]>([]);
  const navigate = useNavigateToPage();


  const isEditing = !!configurationId;

  useEffect(() => {
    if (isEditing) {
      getConfiguration();
    }
    getUsersList();
  }, []);

  useEffect(() => {
    const errors = validateConfiguration(configurationName, selectedPlatform, defaultDailyBudget, defaultBid, maxBid, selectedUsers.length);
    if (errors.length > 0) {
      setConfigurationErrors(errors);
    } else {
      setConfigurationErrors([]);
    }
  }, [configurationName, selectedPlatform, defaultDailyBudget, defaultBid, maxBid, selectedUsers]);

  const replaceUsersIdsWithNames = (message: string) => {
    const userIdRegex = /auth0\|[a-zA-Z0-9]+/g;
    const userIds = message.match(userIdRegex) || [];
    const userNames = userIds.map(userId => capitalizeFirstLetter(users.find(user => user.id === userId)?.name || userId));
    return message.replace(/\[.*?\]/, userNames.join(', '));
  };

  const publishConfiguration = async () => {
    setIsLoading(true);
    let errorOccurred = false;
    const configuration = {
      name: configurationName,
      platform: selectedPlatform,
      defaultDailyBudget: parseFloat(defaultDailyBudget),
      defaultBid: parseFloat(defaultBid),
      maxBid: parseFloat(maxBid),
      users: selectedUsers.map(user => user.id)
    };
    try {
      if (isEditing) {
        await ConfigurationsServiceConfigsProvider.updateConfiguration(configurationId, configuration);
      } else {
        await ConfigurationsServiceConfigsProvider.createConfiguration(configuration);
      }
    } catch (error: any) {
      errorOccurred = true;
      let formattedErrorMessage = capitalizeFirstLetterOfSentences(error?.messages) || GENERIC_ERROR_MESSAGE;
      if (error?.code === 409) {
        if (error?.messages?.includes(USERS_CONFLICT_MESSAGE)) {
          formattedErrorMessage = replaceUsersIdsWithNames(formattedErrorMessage);
          setErrorMessage(formattedErrorMessage);
        } else {
          setErrorMessage(formattedErrorMessage);
        }
      }
      setIsError(true);
    }

    setIsLoading(false);
    getUsers();
    if (!errorOccurred) {
      navigate('campaign-settings');
    }
  };

  const getUsers = async () => {
    const usersResponse = await UsersServiceConfigsProvider.getUsersList();
    setConfigurationData({ ...configurationData, users: usersResponse.users });
  };

  const getUsersList = async () => {
    if (!users.length) {
      setIsUsersLoading(true);
      await getUsers();
      setIsUsersLoading(false);
    }
  };

  const getConfiguration = async () => {
    setIsLoading(true);
    const getConfigurationResponse = await ConfigurationsServiceConfigsProvider.getConfiguration(configurationId as string);
    setConfigurationName(getConfigurationResponse.name);
    setSelectedPlatform(getConfigurationResponse.platform);
    setDefaultDailyBudget(getConfigurationResponse.defaultDailyBudget.toString());
    setDefaultBid(getConfigurationResponse.defaultBid.toString());
    setSMaxBid(getConfigurationResponse.maxBid.toString());
    setSelectedUsers(users.filter(user => getConfigurationResponse.users.includes(user.id)));
    setIsLoading(false);

  };

  return (
    <>
      <PageHeader title={isEditing ? 'Edit Campaign Settings' : 'New Campaign Settings'} onBackClicked={() => navigate('campaign-settings')}>
        <CssTooltip
          content={configurationErrors.length > 0 ? <TooltipContent messages={configurationErrors}/> : ''}
          style={styles.tooltip as any}
          position={TOOLTIP_POSITIONS.BOTTOM_LEFT}
          variables={{
            tooltipMultilineWidth: 'fit-content',
          }}
        >
          <Button
            onClick={publishConfiguration}
            disabled={configurationErrors.length > 0}>{isEditing ? 'Save Changes' : 'Create'}
          </Button>
        </CssTooltip>
      </PageHeader>
      <div css={styles.container}>
        {isError && <div css={styles.snackBar}>
          <SnackBar
            text={errorMessage}
            status={SNACK_BAR_STATUSES.ERROR}
            stayOpen={true}
            variables={{ animationDuration: '2s' }}
            onClose={() => {
              setIsError(false);
              setErrorMessage('');
            }}
          />
        </div>}
        {isLoading
          ? <Loader size={Loader.LOADER_SIZES.FULL_SCREEN}/>
          : <div css={styles.wrapper}>
            <div css={styles.formRows}>
              <GeneralData
                configurationName={configurationName}
                setConfigurationName={setConfigurationName}
                selectedPlatform={selectedPlatform}
                setSelectedPlatform={setSelectedPlatform}
                isEditing={isEditing}
              />
              <div css={styles.settingsAndUsers}>
                <CampaignSettings
                  defaultDailyBudget={defaultDailyBudget}
                  setDefaultDailyBudget={setDefaultDailyBudget}
                  defaultBid={defaultBid}
                  setDefaultBid={setDefaultBid}
                  maxBid={maxBid}
                  setMaxBid={setSMaxBid}
                />
                <SelectUsers
                  selectedUsers={selectedUsers}
                  usersList={users}
                  setSelectedUsers={setSelectedUsers}
                  isLoading={isUsersLoading}
                />
              </div>
            </div>
            <SelectionSummary
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
            />
          </div>}
      </div>
    </>

  );
};
