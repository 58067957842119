/** @jsxImportSource @emotion/react */
import { User } from '../../core/types/users.types';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ActionsMenu, Card, Checkbox, Input, Loader, Text } from '@ftbpro/mm-admin-ui-components';
import { SearchIcon } from '@ftbpro/mm-admin-assets';
import { capitalizeFirstLetter } from '@ftbpro/mm-admin-core-utils';
import { css } from '@emotion/react';

export const styles = {
  horizontalInputRow: css({
    display: 'grid',
    gridTemplateColumns: '1fr 20px',
    width: '100%',
    marginBottom: '5px',
  }),
  overflow: css({
    overflowY: 'auto',
    height: '288px',
  }),
  checkbox: css({
    paddingTop: '10px',
    paddingBottom: '10px',
    borderBottom: '1px solid',
    borderBottomColor: '#dbd9e0'
  }),
  centerLine: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  cardStyle: {
    width: '100%',
    textWrap: 'wrap',
    overflowWrap: 'anywhere',
    overflow: 'wrap',
    boxShadow: 'none',
  },
  loaderContainer: css({
    width: '100%',
    height: '191px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  actionMenu: css({
    position: 'relative',
    right: '15px',
  })
};

interface SelectUsersProps {
  selectedUsers: User[],
  usersList: User[],
  setSelectedUsers: Dispatch<SetStateAction<User[]>>,
  isLoading: boolean,
}


export const SelectUsers = ({
                              selectedUsers,
                              usersList,
                              setSelectedUsers,
                              isLoading,
                            }: SelectUsersProps) => {
  const [searchUsersValue, setSearchUsersValue] = useState<string>('');
  const [searchUsersItems, setSearchUsersItems] = useState<User[]>(usersList || []);

  useEffect(() => {
    if (!isLoading) {
      setSearchUsersItems(usersList || []);
    }
  }, [isLoading, usersList]);

  const onUsersClick = (user: User) => {
    const isSelected = selectedUsers.some(userItem => userItem.id === user.id);
    if (!isSelected) {
      setSelectedUsers(val => {
        const updatedSelectedUsers = [...val, user];
        return updatedSelectedUsers;
      });
    } else {
      setSelectedUsers(val => {
        const updatedSelectedUsers = val.filter(userItem => userItem.email !== user.email);
        return updatedSelectedUsers;
      });
    }
  };

  const onClearAll = () => {
      const updatedSelectedUsersList = selectedUsers.filter(selectedUser => {
        return !searchUsersItems.some(searchUser => searchUser.id === selectedUser.id);
      });
      setSelectedUsers(updatedSelectedUsersList);
  };

  const onIncludeAll = () => {
    const updatedSelectedUsersList = [...selectedUsers];

    searchUsersItems.forEach(searchItem => {
      const exists = selectedUsers.some(selectedUser => selectedUser.id === searchItem.id);

      if (!exists) {
        updatedSelectedUsersList.push(searchItem);
      }
    });

    setSelectedUsers(updatedSelectedUsersList);
  };
  return (
            <Card
              title={'Select Users' + (selectedUsers.length ? ' (' + selectedUsers.length + ')' : '')}
              textType={Text.TEXT_TYPES.TITLE_S}
              variables={{
                cardHeight: '422px',
              }}
              style={styles.cardStyle}
            >
                {
                  isLoading ?
                    <div css={styles.loaderContainer}>
                      <Loader size={Loader.LOADER_SIZES.SMALL}/>
                    </div>
                    :
                    <div css={styles.horizontalInputRow}>

                      <Input
                        clearable
                        onClear={() => {
                          setSearchUsersValue('');
                          setSearchUsersItems(usersList);
                        }}
                        style={{ width: '93%' }}
                        icon={SearchIcon}
                        placeholder="Search Users"
                        onChange={(e: React.SyntheticEvent<HTMLElement, Event>) => {
                          const inputElement = e.target as HTMLInputElement;
                          setSearchUsersValue(inputElement.value);
                          if (!inputElement.value) {
                            setSearchUsersItems(usersList);
                          } else {
                            setSearchUsersItems(usersList.filter(user => user.email.toLowerCase().includes(inputElement.value.toLowerCase()) || user.name.toLowerCase().includes(inputElement.value.toLowerCase())));
                          }
                        }}
                        value={searchUsersValue}
                        variables={{
                          borderColor: '#e4ecff'
                        }}
                        autoHeight
                      />
                      <span css={styles.centerLine}>
                                {usersList.length > 0 && <ActionsMenu
                                  style={styles.actionMenu}
                                  elements={[
                                    {
                                      actionName: 'Clear All',
                                      disabled: false,
                                      onClick: () => {
                                        onClearAll();
                                      }
                                    },
                                    {
                                      actionName: 'Include All',
                                      onClick: () => {
                                        onIncludeAll();
                                      }
                                    },
                                  ]}
                                  itemId="id"
                                />}
                            </span>
                    </div>
                }


              <div css={styles.overflow}>
                {searchUsersItems.length > 0 && searchUsersItems.map((user) => (
                     <div key={user.id} css={styles.checkbox}>
                       <Checkbox
                         checked={selectedUsers.some(userItem => userItem.id === user.id)}
                         onChange={() => onUsersClick(user)}
                       >
                         {capitalizeFirstLetter(user.name)} ({user.email})
                       </Checkbox>
                     </div>
                    ))}
                </div>
            </Card>
  );
};
