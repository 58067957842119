/** @jsxImportSource @emotion/react */
import { Button, ButtonType, FontWeight, Modal, Text } from "@ftbpro/mm-admin-ui-components";

const styles = {
  modalButtons: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '32px',
  },
  cancelButton: {
    marginRight: '8px',
  },
}


interface DeleteModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteModal = (props: DeleteModalProps) => {
  const { open, onClose, onDelete } = props;

  const onDeleteUser = () => {
    onDelete();
    onClose();
  }
  return (
    <Modal
      closeable
      onClose={onClose}
      title="Delete Template Users"
      open={open}
    >
      <div>
        <Text
          type={Text.TEXT_TYPES.PARAGRAPH_XL}
          fontWeight={FontWeight.Bold}
          style={{ marginBottom: '10px' }}
        >
          {"Are you sure you want to delete?"}
        </Text>
        <Text
          type={Text.TEXT_TYPES.PARAGRAPH_L}>
          {"As the owner, removing yourself from this template's user list, will revoke your access to it."}
        </Text>
        <div css={styles.modalButtons}>
          <Button
            content="Cancel"
            onClick={onClose}
            type={ButtonType.Secondary}
            style={styles.cancelButton}
          />
          <Button
            content="Delete"
            onClick={onDeleteUser}
          />
        </div>
      </div>
    </Modal>
  )
};
