/** @jsxImportSource @emotion/react */
import { GroupType } from '../../../groups.enums';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { groupTypedItem } from '../../../groups.types';
import { ActionsMenu, Card, Checkbox, Input, Loader, Text } from '@ftbpro/mm-admin-ui-components';
import { SearchIcon } from '@ftbpro/mm-admin-assets';
import { UsersServiceConfigsProvider } from '../../../../../services/users/UsersServiceConfigsProvider';
import { getTypeName } from '../../../groups.utils';
import { css } from '@emotion/react';

const styles = {
    horizontalInputRow: css({
        display: 'grid',
        gridTemplateColumns: '1fr 20px',
        width: '100%',
        marginBottom: '5px',
    }),
    overflow: css({
        overflowY: 'auto',
        height: '288px',
    }),
    checkbox: css({
        paddingTop: '10px',
        paddingBottom: '10px',
        borderBottom: '1px solid',
        borderBottomColor: '#dbd9e0'
    }),
    relative: css({
        position: 'relative',
        right: '15px',
    }),
    center: css({
        width: '100%',
        height: '191px',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
    }),
    centerLine: css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }),
};
interface SelectGroupItemProps {
    selectedPlatform: string,
    selectedGroupType: GroupType,
    selectedGroupTypedItemList: groupTypedItem[],
    setSelectedGroupTypedItemList: Dispatch<SetStateAction<groupTypedItem[]>>,
}

export const SelectGroupItem = (props: SelectGroupItemProps) => {
    const {
        selectedPlatform,
        selectedGroupType,
        selectedGroupTypedItemList,
        setSelectedGroupTypedItemList,
    } = props
    const [isLoading, setIsLoading] = useState(false);
    const [groupTypedItemList, setGroupTypedItemList] = useState<groupTypedItem[]>([]);
    const [searchGroupItems, setSearchGroupItems] = useState<groupTypedItem[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');

    useEffect(() => {
        if (selectedPlatform && selectedGroupType) {
            setIsLoading(true);
            getGroupTypedItemData()
                .catch(() => {
                    setIsLoading(false);
                });
        }
    }, [selectedPlatform, selectedGroupType]);

    const getGroupTypedItemData = async () => {
        const groupsResponse = await UsersServiceConfigsProvider.getGroupItems(selectedGroupType, selectedPlatform);
        setIsLoading(false);
        const groupItems = groupsResponse.items;

        setGroupTypedItemList(groupItems || []);
        setSearchValue('');
        setSearchGroupItems(groupItems || []);
    };

    const onClearAll = () => {
        const updatedSelectedGroupTypedItemList = selectedGroupTypedItemList.filter(selectedItem => {
            return !searchGroupItems.some(searchItem => searchItem.item === selectedItem.item);
        });
        setSelectedGroupTypedItemList(updatedSelectedGroupTypedItemList);
    }

    const onIncludeAll = () => {
        const updatedSelectedGroupTypedItemList = [...selectedGroupTypedItemList];

        searchGroupItems.forEach(searchItem => {
            const exists = selectedGroupTypedItemList.some(item => item.item === searchItem.item);

            if (!exists) {
                updatedSelectedGroupTypedItemList.push(searchItem);
            }
        });

        setSelectedGroupTypedItemList(updatedSelectedGroupTypedItemList);
    }


    const onGroupsTypedListClick = (groupTypedItem: groupTypedItem) => {
        const isSelected = selectedGroupTypedItemList.some(listItem => listItem.item === groupTypedItem.item);
        if (!isSelected) {
            setSelectedGroupTypedItemList(val => {
                const updatedSelectedGroupTypedItemList = [...val, groupTypedItem];
                return updatedSelectedGroupTypedItemList;
            })
        } else {
            setSelectedGroupTypedItemList(val => {
                const updatedSelectedGroupTypedItemList = val.filter(listItem => listItem.item !== groupTypedItem.item);
                return updatedSelectedGroupTypedItemList;
            })
        }
    }

    return (
        <span>
            <Card
                title={"Select " + getTypeName(selectedGroupType) + (selectedGroupTypedItemList.length ? " (" + selectedGroupTypedItemList.length + ")" : '')}
                textType={Text.TEXT_TYPES.TITLE_S}
                variables={{
                    cardHeight: '422px',
                }}
                style={{
                    width: '100%',
                    textWrap: 'wrap',
                    overflowWrap: 'anywhere',
                    boxShadow: 'none'
                }}
            >
              {isLoading ?
                  <div css={styles.center}><Loader
                      size={Loader.LOADER_SIZES.SMALL}/></div> :
                  <div>
                      <div css={styles.horizontalInputRow}>
                    <span>
                           <Input
                               clearable
                               onClear={() => {
                                   setSearchValue('');
                                   setSearchGroupItems(groupTypedItemList);
                               }}
                               style={{ width: '93%' }}
                               icon={SearchIcon}
                               placeholder={"Search " + selectedGroupType}
                               onChange={(e: React.SyntheticEvent<HTMLElement, Event>) => {
                                   const inputElement = e.target as HTMLInputElement;
                                   setSearchValue(inputElement.value);
                                   if (!inputElement.value) {
                                       setSearchGroupItems(groupTypedItemList);
                                   }
                                   setSearchGroupItems(groupTypedItemList.filter(item => item.item.includes(inputElement.value)));
                               }}
                               variables={{
                                   borderColor: '#e4ecff'
                               }}
                               value={searchValue}
                               autoHeight
                           />
                    </span>
                          <span css={styles.centerLine}>
                              {groupTypedItemList.length > 0 && <ActionsMenu
                                  style={styles.relative}
                                  elements={[
                                      {
                                          actionName: 'Clear All',
                                          disabled: false,
                                          onClick: () => {
                                              onClearAll();
                                          }
                                      },
                                      {
                                          actionName: 'Include All',
                                          onClick: () => {
                                              onIncludeAll();
                                          }

                                      },
                                  ]}
                                  itemId="id"
                              />}
                    </span>

                      </div>
                      <div css={styles.overflow}>
                          {searchGroupItems.length > 0 && searchGroupItems.map((item, index) => (
                              <div key={index} css={styles.checkbox}>
                                  <Checkbox
                                      checked={selectedGroupTypedItemList.some(listItem => listItem.item === item.item)}
                                      onChange={() => onGroupsTypedListClick(item)}
                                  >
                                      {item.item}
                                  </Checkbox>
                              </div>
                          ))}
                      </div>
                  </div>}

                </Card>
                </span>
    )
};
