import { useRecoilState } from 'recoil';
import { settingsState } from '../../settingsState/settings.atom';
import { Checkbox, Text } from '@ftbpro/mm-admin-ui-components';
import { Card } from '../../../Card/Card';


export const PausedCampaign = () => {
  const [settings, setSettings] = useRecoilState(settingsState);
  const { isPausedCampaign } = settings;


  const onPausedCampaignChange = () => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      isPausedCampaign: !isPausedCampaign
    }));
  };

  return (
    <Card>
      <Checkbox
        checked={isPausedCampaign}
        onChange={onPausedCampaignChange}
      >
        <Text type={Text.TEXT_TYPES.PARAGRAPH_M}>Create Paused Campaign</Text>
      </Checkbox>
    </Card>

  );
};
