export enum Verticals {
  Biography = 'Biography',
  Celebs = 'Celebs',
  LifeHacks = 'DIY/Life hacks',
  Facts = 'Facts',
  Funny = 'Funny',
  History = 'History',
  Sports = 'Sports',
  Unique = 'Unique/Unbelievable-stories'
}

export enum PlatformsType {
  Google = 'Google',
  Facebook = 'Facebook',
  Outbrain = 'Outbrain',
  Taboola = 'Taboola',
}

export enum ActivityType {
  EMPTY = '',
  PMWZNY = 'PMWZNY',
  PMWZ = 'PMWZ',
  GrowthFB = 'Growth-FB',
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}
