import { groupTypedItem } from '../../../groups.types';
import { User } from '../../../../../core/types/users.types';
import React, { Dispatch, SetStateAction } from 'react';
import { Summary } from '@ftbpro/mm-admin-ui-components';
import { GroupType } from '../../../groups.enums';
import { getTypeName } from '../../../groups.utils';

interface SelectionSummaryProps {
    selectedUsers: User[],
    setSelectedUsers: Dispatch<SetStateAction<User[]>>,
    selectedGroupType: GroupType,
    selectedGroupTypedItemList: groupTypedItem[],
    setSelectedGroupTypedItemList: Dispatch<SetStateAction<groupTypedItem[]>>,
}

export const SelectionSummary = ({
                                     selectedUsers,
                                     setSelectedUsers,
                                     selectedGroupType,
                                     selectedGroupTypedItemList,
                                     setSelectedGroupTypedItemList,
                                 }: SelectionSummaryProps) => {

    const summerySectionsData = [
        {
            sectionItems: selectedUsers.map(user => {
                return {
                    id: user.id,
                    name: user.email,
                }
            }),
            sectionTitle: 'Users'
        },
        {
            sectionItems: selectedGroupTypedItemList.map(item => {
                return {
                    id: item.item,
                    name: item.item,
                }
            }),
            sectionTitle: getTypeName(selectedGroupType)
        }
    ];

    return (
        <div>
            <Summary
                onClearAll={() => {
                    setSelectedUsers([]);
                    setSelectedGroupTypedItemList([]);
                }}
                onRemoveItem={(summeryItem, itemType) => {
                    if (itemType === 'Users') {
                        setSelectedUsers(val => {
                            const updatedSelectedUsers = val.filter(userItem => userItem.id !== summeryItem.id);
                            return updatedSelectedUsers;
                        });
                    } else {
                        setSelectedGroupTypedItemList(val => {
                            const updatedSelectedGroupTypedItemList = val.filter(listItem => listItem.item !== summeryItem.id);
                            return updatedSelectedGroupTypedItemList;
                        })
                    }
                }}
                sectionsData={summerySectionsData}
                title="Summary"
                variables={{
                    width: '100%',
                }}
                style={{ height: '655px', overflow: 'scroll' }}
            />
        </div>
    )
};
